import * as actiontypes from "./actionTypes";

import * as axios from "../../axios/axiosCreation";

const headers = {
  "Content-Type": "application/json;charset=UTF-8",
  "Access-Control-Allow-Origin": "*",
};

const Loading = (val) => {
  return {
    type: actiontypes.LELoading,
    value: val,
  };
};

const Response = (message) => {
  return {
    type: actiontypes.LEResponse,
    value: message,
  };
};

export const LEClearAll = () => {
  return {
    type: actiontypes.LEClearAll,
  };
};
export const LEClearData = () => {
  return {
    type: actiontypes.LEClearData,
  };
};

const LELeaveLoad = (data) => {
  return {
    type: actiontypes.LELeaveLoad,
    value: data,
  };
};

export const GetLeaves_DB = (serviceId, token, search = "") => {
  return (dispatch) => {
    dispatch(Loading(true));
    axios.LeaveURL.get(`/${serviceId}`, {
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
      },
      params: {
        date: search,
      },
    })
      .then((res) => {
        dispatch(LELeaveLoad(res.data));
        dispatch(Loading(false));
      })
      .catch((err) => {
        dispatch(Response(err.response.data.error));
        dispatch(Loading(false));
      });
  };
};

//for Leave edit

export const LEDSetState = (key, valid, errorMessage) => {
  return {
    type: actiontypes.LEDsetstate,
    inputType: key,
    payload: {
      is_valid: valid,
      error_msg: errorMessage,
    },
  };
};

export const LEDSetValue = (entered_text, type) => {
  return {
    type: actiontypes.LEDsetvalue,
    inputType: type,
    payload: {
      value: entered_text,
    },
  };
};

export const LEDSaveLeave_DB = (data, token) => {
  return (dispatch) => {
    axios.LeaveURL.post("/", data, {
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
      },
    })
      .then(() => {
        dispatch(Response("Successfully Saved"));
      })
      .catch((err) => {
        dispatch(Response(err.response.data.error));
      });
  };
};

//Delete Leave
export const LEDDelete_DB = (id, token) => {
  return (dispatch) => {
    axios.LeaveURL.delete(`/${id}`, {
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
      },
    })
      .then(() => {
        dispatch(Response("Successfully Deleted"));
      })
      .catch((err) => {
        dispatch(Response(err.response.data.error));
      });
  };
};
