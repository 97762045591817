import * as actiontypes from "../actions/actionTypes";

const initialState = {
  userList: [],
  loading: false,
  response: "",
  success: false,
  data: {
    _id: { value: null },
    userName: {
      value: "",
      validation: {
        required: true,
      },
      is_valid: true,
      error_msg: "",
      max_length: 50,
    },
    password: {
      value: "",
      validation: {
        required: true,
      },
      is_valid: true,
      error_msg: "",
      max_length: 50,
    },
    phoneNumber: {
      value: "",
      validation: {
        required: true,
        numeric: true,
      },
      is_valid: true,
      error_msg: "",
      max_length: 50,
    },
  },
};

const UserReducer = (state = initialState, action) => {
  let updated;
  let updated_data;
  switch (action.type) {
    case actiontypes.UClearAll:
      updated = initialState;
      return updated;
    case actiontypes.UClearData:
      updated_data = { ...state.data };
      for (const key in updated_data) {
        updated_data[key].value = "";
      }
      updated = {
        ...state,
        data: {
          ...updated_data,
        },
      };
      return updated;
    case actiontypes.UserLoad:
      updated = {
        ...state,
        userList: [...action.value],
      };
      return updated;
    case actiontypes.ULoading:
      updated = {
        ...state,
        loading: action.value,
      };
      return updated;
    case actiontypes.UResponse:
      updated = {
        ...state,
        response: action.value,
      };
      return updated;
    case actiontypes.USuccess:
      updated = {
        ...state,
        success: action.value,
      };
      return updated;

    case actiontypes.UDsetstate:
      updated = { ...state.data[action.inputType] };
      for (const key in action.payload) {
        updated[key] = action.payload[key];
      }
      let updated_state = {
        ...state,
        data: {
          ...state.data,
          [action.inputType]: updated,
        },
      };
      return updated_state;

    case actiontypes.UDsetvalue:
      updated = {
        ...state,
        data: {
          ...state.data,
          [action.inputType]: {
            ...state.data[action.inputType],
            value: action.payload.value,
          },
        },
      };
      return updated;
    case actiontypes.UDForEdit:
      let user = state.userList.filter((user) => user._id === action.value);
      updated_data = { ...state.data };
      for (const key in updated_data) {
        updated_data[key].value = user[0][key];
      }
      updated = {
        ...state,
        data: {
          ...updated_data,
        },
      };
      return updated;
    default:
      break;
  }
  return state;
};

export default UserReducer;
