import React from "react";
import Fade from "@material-ui/core/Fade";
import CircularProgress from "@material-ui/core/CircularProgress";

const ScreenLoad = (props) => {
  return (
    <div
      style={{
        height: 70,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Fade
        in={true}
        style={{
          transitionDelay: props.loading ? "800ms" : "0ms",
        }}
        unmountOnExit
      >
        <CircularProgress />
      </Fade>
    </div>
  );
};

export default ScreenLoad;
