import React from "react";
import { makeStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import GetAppIcon from "@material-ui/icons/GetApp";
import IconButton from "@material-ui/core/IconButton";

import withWidth, { isWidthUp } from "@material-ui/core/withWidth";

import SearchBar from "../../common/searchBar";
import { SearchDate } from "../../common/dateTimeElements";
import { ButtonElement } from "../../common/formElements";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 5,
    display: "flex",
    backgroundColor: theme.palette.primary.main,
    justifyContent: "flex-end",
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
  },

  item: {
    margin: 3,
    marginRight: 15,
  },
  buttons: {
    display: "flex",
    justifyContent: "space-around",
  },
  closeicon: {
    color: theme.palette.secondary.light,
  },
  iconbutton: {
    padding: 5,
    margin: "0 5px 0 5px",
  },
  downloadIcon: {
    color: "white",
  },
}));

const SearchForm = (props) => {
  const classes = useStyles();

  const onSearcBarClick = (e) => {
    if (e.keyCode === 13) {
      props.onclick();
    }
  };

  return (
    <div className={classes.container}>
      <Grid
        container
        justify={isWidthUp("md", props.width) ? "flex-end" : "center"}
      >
        <Grid className={classes.item}>
          <SearchBar
            width={"30ch"}
            value={props.search}
            setstate={props.setSearch}
            onclick={onSearcBarClick}
          />
        </Grid>
        <Grid className={classes.item}>
          <Grid container>
            <SearchDate
              value={props.sdate}
              setstate={props.ssetDate}
              label="Start Date"
            />
            <SearchDate
              value={props.edate}
              setstate={props.esetDate}
              label="End Date"
            />
          </Grid>
        </Grid>

        <Grid className={classes.item}>
          <div className={classes.buttons}>
            <ButtonElement
              buttonText="Search"
              color="grey"
              nopadding={true}
              nomargin={true}
              border={true}
              onclick={() => props.onclick()}
            />
            <ButtonElement
              buttonText="Clear"
              nopadding={true}
              nomargin={true}
              color="secondary"
              onclick={() => props.onclose()}
            />
            <IconButton
              className={classes.iconbutton}
              onClick={() => props.ondownload()}
            >
              <GetAppIcon className={classes.downloadIcon} />
            </IconButton>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default withWidth()(SearchForm);
