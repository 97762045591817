import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import DoubleButton from "../../common/doubleButton";

import { TextElement } from "../../common/formElements";
import ScreenLoad from "../../common/loadingComponent";

const GridThree = (props) => {
  let content;
  content = (
    <div style={{ width: "100%" }}>
      <DoubleButton
        leftText="Cancel"
        leftClick={props.closeform}
        rightText="Verify"
        rightClick={props.Verify}
      />
    </div>
  );
  console.log(props.loading, props.patientId);
  if (props.loading) {
    content = <ScreenLoad />;
  } else if (props.verified) {
    if (props.patientId) {
      content = (
        <div style={{ width: "100%" }}>
          <Grid item>
            <Typography>You already booked once</Typography>
          </Grid>
          <DoubleButton
            leftText="Cancel"
            leftClick={props.back}
            rightText="Book Appointment"
            rightClick={props.BookAppointment}
          />
        </div>
      );
    } else {
      content = (
        <div>
          <TextElement
            width={250}
            label="Street"
            id="street"
            value={props.street.value}
            error_msg={props.street.error_msg}
            valid={!props.street.is_valid}
            setvalue={props.SetValue}
            max_length={props.street.max_length}
          />
          <TextElement
            width={250}
            label="City"
            id="city"
            value={props.city.value}
            error_msg={props.city.error_msg}
            valid={!props.city.is_valid}
            setvalue={props.SetValue}
            max_length={props.city.max_length}
          />
          <TextElement
            width={250}
            label="Zip"
            id="zip"
            value={props.zip.value}
            error_msg={props.zip.error_msg}
            valid={!props.zip.is_valid}
            setvalue={props.SetValue}
            max_length={props.zip.max_length}
          />
          <DoubleButton
            leftText="Cancel"
            leftClick={props.back}
            rightText="Save"
            rightClick={props.VerifyNumber}
          />
        </div>
      );
    }
  }

  return (
    <Grid container style={{ padding: 10 }} justify="center">
      {content}
    </Grid>
  );
};

export default GridThree;
