import React from "react";
import "./App.css";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import { BrowserRouter } from "react-router-dom";

import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import { Provider } from "react-redux";

import theme from "./theme";

import { Route, Switch } from "react-router-dom";
import thunk from "redux-thunk";

import MainScreen from "./screens/MainScreen";
import LoginScreen from "./screens/loginScreen";

import ServiceReducer from "./store/reducers/serviceReducer";
import ServiceInfoReducer from "./store/reducers/serviceInfoReducer";
import ServiceAlterReducer from "./store/reducers/alterServiceReducer";
import LeaveReducer from "./store/reducers/leaveReducer";
import AppointmentReducer from "./store/reducers/appointmentReducer";
import userReducer from "./store/reducers/userReducer";
import LoginReducer from "./store/reducers/loginReducer";
import UpdatesReducer from "./store/reducers/updatesReducer";

const composeEnhancers =
  (typeof window !== "undefined" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const enhancer = composeEnhancers(applyMiddleware(thunk));

const rootreducer = combineReducers({
  login: LoginReducer,
  service: ServiceReducer,
  serviceInfo: ServiceInfoReducer,
  alterService: ServiceAlterReducer,
  leave: LeaveReducer,
  appointment: AppointmentReducer,
  account: userReducer,
  update: UpdatesReducer,
});

const store = createStore(rootreducer, enhancer);

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <div className="App">
            <Switch>
              <Route path="/login" exact component={LoginScreen} />
              <Route path="/" component={MainScreen}></Route>
            </Switch>
            <CssBaseline />
          </div>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
