import axios from "axios";

export const axios_ori = axios;

export const LoginURL = axios.create({
  baseURL: "/api/login",
});

export const AppointmentURL = axios.create({
  baseURL: "/api/appointments",
});

export const AppointmentURL_User = axios.create({
  baseURL: "/api/appointments-user",
});

export const GetSlotsURL = axios.create({
  baseURL: "/api/getslots",
});

export const getService = "/api/services-user/";

export const ServiceURL = axios.create({
  baseURL: "/api/services",
});
export const ServiceInfoURL = axios.create({
  baseURL: "/api/servicesinfos",
});
export const AlterServiceURL = axios.create({
  baseURL: "/api/alterservices",
});

export const LeaveURL = axios.create({
  baseURL: "/api/leaves",
});

export const UserURL = axios.create({
  baseURL: "/api/users",
});

export const UpdateURL = axios.create({
  baseURL: "/api/updates",
});

export const PhoneVerificationURL = axios.create({
  baseURL: "/api/verifyPhoneNumber",
});

export const updatesSetVisible = "/api/updates-visiblelist";
export const uploads = "/uploads";
export const appointmentExcel = "/api/appointments-excel";

export const checkUserURL = "api/patientId-user";

export const ConsultedURL = "/api/appointments-consulted";

LoginURL.defaults.headers["Content-Type"] = "application/json";
AppointmentURL.defaults.headers["Content-Type"] = "application/json";
ServiceURL.defaults.headers["Content-Type"] = "application/json";
ServiceInfoURL.defaults.headers["Content-Type"] = "application/json";
AlterServiceURL.defaults.headers["Content-Type"] = "application/json";
UserURL.defaults.headers["Content-Type"] = "application/json";
