import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";

import { connect } from "react-redux";
import * as actioncreators from "../../../store/actions/actionCreators";

import { ButtonElement } from "../../common/formElements";
import { DatePicker } from "../../common/dateTimeElements";
import DateTimeValidator from "../../validation/dateTimeValidator";

const useStyles = makeStyles((theme) => ({
  title: {
    padding: 15,
  },
  buttons: {
    marginTop: 20,
  },
  body: {
    marginTop: 25,
  },
}));

const LeaveForm = (props) => {
  const classes = useStyles();
  const submitform = () => {
    let valid = DateTimeValidator(props.formdata, props.SetState);
    if (valid) {
      let data = {
        serviceId: props.serviceId,
        date: props.formdata.date.value,
      };
      props.PostForm(data, props.token);
    }
  };

  const closeform = () => {
    props.onCancel();
  };

  return (
    <div>
      <Grid className={classes.title}>
        <Typography variant="h6">Add Leave</Typography>
      </Grid>
      <Divider variant="middle" />
      <Grid container className={classes.body} justify="center">
        <DatePicker
          label="From Date"
          id="date"
          value={props.formdata.date.value}
          error_msg={props.formdata.date.error_msg}
          valid={!props.formdata.date.is_valid}
          setvalue={props.SetValue}
        />
      </Grid>
      <Grid container className={classes.buttons}>
        <Grid item xs={6}>
          <Grid container justify="center">
            <ButtonElement
              buttonText="cancel"
              variant="outlined"
              color="secondary"
              onclick={() => closeform()}
            />
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container justify="center">
            <ButtonElement buttonText="Save" onclick={submitform} />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const mapstatetoprops = (state) => {
  return {
    formdata: state.leave.data,
    serviceId: state.service.showId,
    token: state.login.token,
  };
};

const mapdispatchtoprops = (dispatch) => {
  return {
    SetState: (key, valid, errorMessage) =>
      dispatch(actioncreators.LEDSetState(key, valid, errorMessage)),
    SetValue: (entered_text, type) =>
      dispatch(actioncreators.LEDSetValue(entered_text, type)),
    PostForm: (data, token) =>
      dispatch(actioncreators.LEDSaveLeave_DB(data, token)),
  };
};

export default connect(mapstatetoprops, mapdispatchtoprops)(LeaveForm);
