import * as actiontypes from "../actions/actionTypes";

import * as axios from "../../axios/axiosCreation";

const headers = {
  "Content-Type": "application/json;charset=UTF-8",
  "Access-Control-Allow-Origin": "*",
};

const Loading = (val) => {
  return {
    type: actiontypes.UPLoading,
    value: val,
  };
};

const Response = (message) => {
  return {
    type: actiontypes.UPResponse,
    value: message,
  };
};

export const UPsetVisibility = (id) => {
  return {
    type: actiontypes.UPsetVisibility,
    value: id,
  };
};

const SetUpdateList = (data) => {
  return {
    type: actiontypes.UPSetUpdateList,
    value: data,
  };
};

export const UPGetUpdates_DB = (token) => {
  return (dispatch) => {
    dispatch(Loading(true));
    axios.UpdateURL.get("/", {
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        dispatch(SetUpdateList(res.data));
        dispatch(Response(""));
        dispatch(Loading(false));
      })
      .catch((err) => {
        dispatch(Response(err.response.data.error));
        dispatch(Loading(false));
      });
  };
};

export const UPUploadImage = (data, token) => {
  return (dispatch) => {
    //dispatch(Loading(true));
    const formData = new FormData();
    formData.append("update", data.image);
    formData.append("caption", data.caption);
    axios.UpdateURL.post("/", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        dispatch(Response("Successfully Added"));
        //dispatch(Loading(false));
      })
      .catch((err) => {
        dispatch(Response(err.response.data.error));
        //dispatch(Loading(false));
      });
  };
};

export const UPDeleteImage = (id, token) => {
  return (dispatch) => {
    //dispatch(Loading(true));
    axios.UpdateURL.delete(`/${id}`, {
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        dispatch(Response("Successfully Deleted"));
        //dispatch(Loading(false));
      })
      .catch((err) => {
        dispatch(Response(err.response.data.error));
        //dispatch(Loading(false));
      });
  };
};

export const UPSetVisible = (imageids, token) => {
  return (dispatch) => {
    //dispatch(Loading(true));
    axios.axios_ori
      .post(
        axios.updatesSetVisible,
        { updateIds: [...imageids] },
        {
          headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        dispatch(Response("Successfully Set as Visible"));
        //dispatch(Loading(false));
      })
      .catch((err) => {
        dispatch(Response(err.response.data.error));
        //dispatch(Loading(false));
      });
  };
};
