import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Button from "@material-ui/core/Button";

import withWidth, { isWidthUp } from "@material-ui/core/withWidth";

import { withRouter } from "react-router";

import SideDrawer from "./drawer";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appbar: {
    position: "fixed",
    zIndex: 1250,
  },

  menuButton: {
    marginRight: theme.spacing(5),
  },
  title: {
    flex: 1,
    marginLeft: 50,
  },
  toolbarMargin: {
    ...theme.mixins.toolbar,
  },
}));

const NavBar = (props) => {
  const classes = useStyles();
  const [drawerOpen, set_drawerOpen] = useState(false);
  const [openMiniDrawer, set_openMiniDrawer] = useState(false);

  const toggleDrawerOpen = (action = null) => {
    if (!isWidthUp("sm", props.width)) {
      if (action == "close") {
        set_drawerOpen(false);
      } else {
        set_drawerOpen(!drawerOpen);
      }
    }
    if (isWidthUp("sm", props.width)) {
      if (action == "close") {
        set_openMiniDrawer(false);
      } else {
        set_openMiniDrawer(!openMiniDrawer);
      }
    }
  };

  let title;
  if (props.history.location.pathname === "/appointments") {
    title = "Appointments";
  } else if (props.history.location.pathname === "/services") {
    title = "Services";
  } else if (props.history.location.pathname === "/accounts") {
    title = "Accounts";
  } else if (props.history.location.pathname === "/updates") {
    title = "Updates";
  }
  return (
    <div className={classes.root}>
      <AppBar className={classes.appbar}>
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawerOpen}
          >
            <MenuIcon />
          </IconButton>
          {!isWidthUp(props.width, "xs") ? (
            <Typography
              variant="h6"
              align="left"
              onClick={() => props.history.push("/appointments")}
              style={{ cursor: "pointer" }}
            >
              Mitra Clinic
            </Typography>
          ) : null}

          <Typography variant="h6" className={classes.title} align="left">
            {title}
          </Typography>
          <Button color="inherit" onClick={() => props.logout()}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>

      <SideDrawer
        open={drawerOpen}
        openmini={openMiniDrawer}
        close={toggleDrawerOpen}
      />
    </div>
  );
};

export default withRouter(withWidth()(NavBar));
