import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";

import { connect } from "react-redux";
import * as actioncreators from "../../store/actions/actionCreators";

import { SearchDate } from "../common/dateTimeElements";
import TabPanel from "../common/tabpanel";
import AlterServiceForm from "./forms/alterServiceForm";
import ScreenLoad from "../common/loadingComponent";
import {
  ResponseDialog,
  DeleteConfirmationDialog,
} from "../common/responseDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  listitem: {
    marginTop: 5,
    backgroundColor: theme.palette.background.paper,
    border: `2px solid ${theme.palette.primary.light}`,
    borderRadius: 3,
    "&:hover": {
      //backgroundColor: theme.palette.primary.light,
      border: `2px solid ${theme.palette.primary.main}`,
      //backgroundColor: theme.palette.primary.main,
      //color: theme.palette.common.white,
    },
  },
  header: {
    overflow: "auto",
    maxHeight: 390,
    padding: 5,
    marginTop: 0,
  },
  headingicon: {
    color: theme.palette.common.white,
  },
  deleteicon: {
    color: theme.palette.secondary.light,
  },
  editicon: {
    color: theme.palette.common.white,
  },
  closeicon: {
    color: theme.palette.secondary.light,
  },
  heading: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
    padding: 8,
    minHeight: 44,
  },
  searchdiv: {
    margin: "auto",
  },
}));

const ServiceAlterListItem = (props) => {
  const classes = useStyles();
  const [visible, set_visible] = useState(false);
  return (
    <div
      className={classes.listitem}
      onMouseEnter={() => set_visible(true)}
      onMouseLeave={() => set_visible(false)}
      id={props.id}
    >
      <ListItem>
        <Grid container>
          <Grid item xs={6} sm={3}>
            <ListItemText primary={new Date(props.date).toDateString()} />
          </Grid>
          <Grid item xs={6} sm={3}>
            <ListItemText primary={props.duration} />
          </Grid>
          <Grid item xs={6} sm={3}>
            <ListItemText primary={props.start_time} />
          </Grid>
          <Grid item xs={6} sm={3}>
            <ListItemText primary={props.end_time} />
          </Grid>
        </Grid>

        <ListItemSecondaryAction>
          {visible ? (
            <>
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => props.delete(props.id)}
              >
                <DeleteIcon className={classes.deleteicon} />
              </IconButton>
            </>
          ) : null}
        </ListItemSecondaryAction>
      </ListItem>
    </div>
  );
};

const ServiceAlterList = (props) => {
  const classes = useStyles();
  const [search_visible, set_search_visible] = useState(false);
  const [value, set_value] = useState(0);
  const [deleteId, set_deleteId] = useState(false);
  const [search, set_search] = useState();

  const [deleteDialog, set_deleteDialog] = useState(false);
  const [responseDialog, set_responseDialog] = useState(false);

  const ShowId = props.serviceId;

  useEffect(() => {
    props.GetAlterServices(ShowId, props.token);
  }, [ShowId]);

  useEffect(() => {
    if (search !== "") {
      props.GetAlterServices(ShowId, props.token, search);
    }
  }, [search]);

  useEffect(() => {
    if (deleteId) {
      set_deleteDialog(true);
    }
  }, [deleteId]);

  const response = props.response;
  useEffect(() => {
    if (response !== "") {
      set_responseDialog(true);
    }
  }, [response]);

  const closeSearch = () => {
    set_search_visible(false);
    props.GetAlterServices(ShowId, props.token);
    set_search(null);
  };

  const openform = () => {
    props.ClearForm();
    set_value(1);
  };

  const onCancelForm = () => {
    props.ClearForm();
    set_value(0);
  };

  const confirmDelete = (serviceId) => {
    set_deleteId(serviceId);
  };

  const deleteAlterService = () => {
    props.OnDelete(deleteId, props.token);
    set_deleteId(false);
    set_deleteDialog(false);
  };

  const closeResponse = () => {
    props.ClearAll();
    props.GetAlterServices(ShowId, props.token);
    set_value(0);
    set_deleteId(false);
    set_responseDialog(false);
  };

  const oncancelDelete = () => {
    set_value(0);
    set_deleteId(false);
    set_deleteDialog(false);
  };
  const fullSearch = (
    <>
      <div className={classes.searchdiv}>
        <SearchDate value={search} setstate={set_search} />
      </div>
      <IconButton edge="end" aria-label="search" onClick={() => closeSearch()}>
        <CloseIcon className={classes.closeicon} />
      </IconButton>
    </>
  );

  const search_button = (
    <IconButton
      edge="end"
      aria-label="search"
      onClick={() => set_search_visible(true)}
    >
      <SearchIcon className={classes.headingicon} />
    </IconButton>
  );

  let content;

  if (props.loading) {
    content = <ScreenLoad />;
  } else {
    let serviceAlters = props.alterServicesList.map((serviceAlter) => (
      <ServiceAlterListItem
        id={serviceAlter._id}
        date={serviceAlter.date}
        start_time={serviceAlter.startTime}
        end_time={serviceAlter.endTime}
        duration={serviceAlter.duration}
        delete={confirmDelete}
      />
    ));
    if (props.alterServicesList.length < 1) {
      serviceAlters = <Typography>No Results found</Typography>;
    }
    content = (
      <>
        <TabPanel value={value} index={0}>
          {serviceAlters}
        </TabPanel>
        <TabPanel value={value} index={1}>
          <AlterServiceForm onCancel={onCancelForm} />
        </TabPanel>
      </>
    );
  }
  return (
    <Grid item xs={12}>
      <div>
        <List dense={true} className={classes.list}>
          <ListItem className={classes.heading}>
            {!search_visible ? (
              <Grid container>
                <Grid item xs={6} sm={3}>
                  <ListItemText primary="Date" />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <ListItemText primary="Duration" />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <ListItemText primary="Start Time" />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <ListItemText primary="End Time" />
                </Grid>
              </Grid>
            ) : null}
            <ListItemSecondaryAction style={{ display: "flex" }}>
              {value === 0
                ? search_visible
                  ? fullSearch
                  : search_button
                : null}

              <IconButton
                edge="end"
                aria-label="add"
                onClick={() => openform()}
              >
                <AddIcon className={classes.headingicon} />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
          <DeleteConfirmationDialog
            open={deleteDialog}
            title="Delete"
            text={"Sure wanno delete ? "}
            oncancel={oncancelDelete}
            onokay={deleteAlterService}
          />
          <ResponseDialog
            open={responseDialog}
            title="Response"
            text={props.response}
            onclose={closeResponse}
          />
          {content}
        </List>
      </div>
    </Grid>
  );
};

const mapstatetoprops = (state) => {
  return {
    loading: state.alterService.loading,
    response: state.alterService.response,
    alterServicesList: state.alterService.alterServicesList,
    serviceId: state.service.showId,
    token: state.login.token,
  };
};

const mapdispatchtoprops = (dispatch) => {
  return {
    GetAlterServices: (serviceid, token, search) =>
      dispatch(actioncreators.GetAlterServices_DB(serviceid, token, search)),
    ClearAll: () => dispatch(actioncreators.SAClearAll()),
    OnDelete: (id, token) => dispatch(actioncreators.SADDelete_DB(id, token)),
    ClearForm: () => dispatch(actioncreators.SAClearData()),
  };
};

export default connect(mapstatetoprops, mapdispatchtoprops)(ServiceAlterList);
