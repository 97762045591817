import * as actiontypes from "../actions/actionTypes";

const initialState = {
  leaveList: [],
  loading: false,
  response: "",
  data: {
    id: {
      value: "",
    },
    date: {
      value: "",
      validation: {
        date: true,
      },
      is_valid: true,
      error_msg: "",
    },
  },
};

const LeaveReducer = (state = initialState, action) => {
  let updated;
  switch (action.type) {
    case actiontypes.LEClearAll:
      updated = initialState;
      return updated;

    case actiontypes.LEClearData:
      updated = {
        ...state,
        data: {
          ...state.data,
          value: "",
          is_valid: true,
          error_msg: "",
        },
      };
      return updated;
    case actiontypes.LELeaveLoad:
      updated = {
        ...state,
        leaveList: [...action.value],
      };
      return updated;

    case actiontypes.LELoading:
      updated = {
        ...state,
        loading: action.value,
      };
      return updated;
    case actiontypes.LEResponse:
      updated = {
        ...state,
        response: action.value,
      };
      return updated;

    case actiontypes.LEDsetstate:
      updated = { ...state.data[action.inputType] };
      for (const key in action.payload) {
        updated[key] = action.payload[key];
      }
      let updated_state = {
        ...state,
        data: {
          ...state.data,
          [action.inputType]: updated,
        },
      };
      return updated_state;
    case actiontypes.LEDsetvalue:
      updated = {
        ...state,
        data: {
          ...state.data,
          [action.inputType]: {
            ...state.data[action.inputType],
            value: action.payload.value,
          },
        },
      };
      return updated;
    default:
      break;
  }
  return state;
};

export default LeaveReducer;
