import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";

import moment from "moment";

import { connect } from "react-redux";
import * as actioncreators from "../../../store/actions/actionCreators";

import { TextElement, ButtonElement } from "../../common/formElements";
import { DatePicker, TimePicker } from "../../common/dateTimeElements";
import validator from "../../validation/validator";
import DateTimeValidator from "../../validation/dateTimeValidator";

const useStyles = makeStyles((theme) => ({
  title: {
    padding: 15,
  },
  buttons: {
    marginTop: 20,
  },
  body: {
    marginTop: 25,
  },
}));

const ServiceInfoForm = (props) => {
  const classes = useStyles();

  const submitform = () => {
    let valid = validator(props.formdata, props.SetState, [
      "fromDate",
      "startTime",
      "endTime",
    ]);
    valid &= DateTimeValidator(props.formdata, props.SetState);
    if (valid) {
      let data = {
        serviceId: props.serviceId,
        fromDate: props.formdata.fromDate.value,
        duration: props.formdata.duration.value,
        startTime: props.formdata.startTime.value,
        endTime: props.formdata.endTime.value,
      };
      props.PostForm(data, props.token);
    }
  };

  const closeform = () => {
    props.onCancel();
  };
  return (
    <div>
      <Grid className={classes.title}>
        <Typography variant="h6">Add Service Info</Typography>
      </Grid>
      <Divider variant="middle" />
      <Grid container className={classes.body}>
        <Grid item xs={12} md={6}>
          <TextElement
            width={250}
            label="Duration"
            id="duration"
            focus={true}
            value={props.formdata.duration.value}
            error_msg={props.formdata.duration.error_msg}
            valid={!props.formdata.duration.is_valid}
            setvalue={props.SetValue}
            max_length={props.formdata.duration.max_length}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DatePicker
            label="From Date"
            id="fromDate"
            value={props.formdata.fromDate.value}
            error_msg={props.formdata.fromDate.error_msg}
            valid={!props.formdata.fromDate.is_valid}
            setvalue={props.SetValue}
            minDate={moment().add(5, "d")}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TimePicker
            label="Start Time"
            id="startTime"
            value={props.formdata.startTime.value}
            error_msg={props.formdata.startTime.error_msg}
            valid={!props.formdata.startTime.is_valid}
            setvalue={props.SetValue}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TimePicker
            label="End Time"
            id="endTime"
            value={props.formdata.endTime.value}
            error_msg={props.formdata.endTime.error_msg}
            valid={!props.formdata.endTime.is_valid}
            setvalue={props.SetValue}
          />
        </Grid>
      </Grid>

      <Grid container className={classes.buttons}>
        <Grid item xs={6}>
          <Grid container justify="center">
            <ButtonElement
              buttonText="cancel"
              variant="outlined"
              color="secondary"
              onclick={() => closeform()}
            />
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container justify="center">
            <ButtonElement buttonText="Save" onclick={submitform} />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const mapstatetoprops = (state) => {
  return {
    formdata: state.serviceInfo.data,
    token: state.login.token,
    serviceId: state.service.showId,
  };
};
const mapdispatchtoprops = (dispatch) => {
  return {
    SetState: (key, valid, errorMessage) =>
      dispatch(actioncreators.SIDSetState(key, valid, errorMessage)),
    SetValue: (entered_text, type) =>
      dispatch(actioncreators.SIDSetValue(entered_text, type)),
    PostForm: (data, token) =>
      dispatch(actioncreators.SIDSaveServiceInfo_DB(data, token)),
  };
};

export default connect(mapstatetoprops, mapdispatchtoprops)(ServiceInfoForm);
