import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import AccountList from "../components/accounts/accountsList";

const AccountScreen = (props) => {
  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <div style={{ padding: 5, margin: 5 }}>
              <AccountList />
            </div>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={6}>
        <Grid item xs={12}>
          <div style={{ padding: 5, margin: 5 }}></div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AccountScreen;
