import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";

import { connect } from "react-redux";
import * as actioncreators from "../../../store/actions/actionCreators";

import { TextAreaElement } from "../../common/formElements";

import {
  ResponseDialog,
  PhoneNumberVerificationDialog,
} from "../../common/responseDialog";

import validator from "../../validation/validator";
import DateTimeValidator from "../../validation/dateTimeValidator";

import GridOne from "./gridOne";
import GridThree from "./gridThree";

const useStyles = makeStyles((theme) => ({
  title: {
    padding: 15,
    marginBottom: 10,
  },
  buttons: {
    marginTop: 20,
  },
  body: {
    padding: 20,
  },
  mainForm: {
    padding: 10,
  },
  descriptionForm: {
    padding: 10,
  },
  dynamicForm: {
    minHeight: 200,
    display: "flex",
    width: "100%",
    alignItems: "flex-end",
  },
}));

const AppointmentForm = (props) => {
  const classes = useStyles();
  useEffect(() => {
    props.GetServices();
  }, []);

  const date = props.formdata.date.value;
  const serviceId = props.formdata.serviceId.value;
  //const serviceId = props.formdata;

  const [responseDialog, set_responseDialog] = useState(false);
  const [verificationDialog, set_verificationDialog] = useState(false);
  const [verificationCode, set_verificationCode] = useState();

  const [verified, set_verified] = useState(false);

  useEffect(() => {
    if (date) {
      props.GetSlots(date, serviceId, props.token);
    }
    props.SetValue("", "slot");
  }, [date, serviceId]);

  const response = props.response;
  useEffect(() => {
    if (response !== "") {
      set_responseDialog(true);
    }
  }, [response]);

  if (props.formdata.serviceId.value === "") {
    if (props.serviceList.length > 0)
      props.SetValue(props.serviceList[0]._id, "serviceId");
  }

  const Verify = () => {
    let valid = validator(props.formdata, props.SetState, [
      "_id",
      "street",
      "city",
      "zip",
      "date",
      "slot",
    ]);
    valid &= DateTimeValidator(props.formdata, props.SetState, [
      "date",
      "slot",
    ]);
    if (valid) {
      props.CheckPatient(
        props.formdata.name.value,
        props.formdata.phoneNumber.value
      );
      set_verified(true);
    }
  };
  const closeform = () => {
    set_verified(false);
    props.ClearForm();
    props.set_value(0);
    props.clearResponse("");
    set_responseDialog(false);
  };

  const onBack = () => {
    set_verified(false);
    props.SetPatientId(null);
  };

  const BookAppointment = () => {
    let valid = validator(props.formdata, props.SetState, [
      "_id",
      "street",
      "city",
      "zip",
      "date",
      "slot",
    ]);
    valid &= DateTimeValidator(props.formdata, props.SetState, [
      "date",
      "slot",
    ]);
    if (valid) {
      let data = {
        name: props.formdata.name.value,
        phoneNumber: props.formdata.phoneNumber.value,
        patientId: props.patientId,
        date: props.formdata.date.value,
        slot: props.formdata.slot.value,
        description: props.formdata.description.value,
        serviceId: props.formdata.serviceId.value,
      };
      props.PostForm(data, props.verifyToken);
    }
  };

  const GetCode = () => {
    let data = {
      phoneNumber: props.formdata.phoneNumber.value,
    };
    props.GetVerifyCode(data, props.formdata.date.value);
  };

  const VerifyNumber = () => {
    let valid = validator(props.formdata, props.SetState, [
      "date",
      "slot",
      "_id",
    ]);
    valid &= DateTimeValidator(props.formdata, props.SetState);
    if (valid) {
      GetCode();
      set_verificationDialog(true);
    }
  };

  const onVerificationOkay = () => {
    props.GetVerifyToken(props.formdata.phoneNumber.value, verificationCode);
  };

  const onBookAppointment = () => {
    if (props.isVerified && props.verifyToken) {
      submitform();
      set_verificationDialog(false);
    }
  };
  const submitform = () => {
    let valid = validator(props.formdata, props.SetState, [
      "date",
      "slot",
      "_id",
    ]);
    valid &= DateTimeValidator(props.formdata, props.SetState);
    if (valid) {
      let data = {
        name: props.formdata.name.value,
        phoneNumber: props.formdata.phoneNumber.value,
        street: props.formdata.street.value,
        city: props.formdata.city.value,
        zip: props.formdata.zip.value,
        date: props.formdata.date.value,
        slot: props.formdata.slot.value,
        description: props.formdata.description.value,
        serviceId: props.formdata.serviceId.value,
      };

      props.PostForm(data, props.verifyToken);
    }

    //}
  };

  const closeVerificationDialog = () => {
    set_verificationDialog(false);
  };

  const closeResponse = () => {
    props.clearResponse("");
    set_responseDialog(false);
  };

  return (
    <div>
      <PhoneNumberVerificationDialog
        open={verificationDialog}
        value={verificationCode}
        setvalue={set_verificationCode}
        title="Phone Number Verification"
        isVerified={props.isVerified}
        oncancel={closeVerificationDialog}
        onokay={props.isVerified ? onBookAppointment : onVerificationOkay}
        loading={props.tokenLoading}
        getcode={GetCode}
      />

      <ResponseDialog
        open={responseDialog}
        title="Response"
        text={props.response}
        onclose={props.success ? closeform : closeResponse}
      />
      <Grid className={classes.title}>
        <Typography variant="h6">{`${"Add"} Appointment`}</Typography>
      </Grid>
      <Divider variant="middle" />
      <Grid container className={classes.body}>
        <GridOne
          verified={verified}
          name={props.formdata.name}
          phoneNumber={props.formdata.phoneNumber}
          serviceList={props.serviceList}
          serviceId={props.formdata.serviceId}
          date={props.formdata.date}
          slot={props.formdata.slot}
          subLoading={props.subLoading}
          subResponse={props.subResponse}
          slotsList={props.slotsList}
          SetValue={props.SetValue}
        />
        <Grid item xs={12} md={4} className={classes.dynamicForm}>
          <GridThree
            loading={props.patientLoading}
            verified={verified}
            street={props.formdata.street}
            city={props.formdata.city}
            zip={props.formdata.zip}
            SetValue={props.SetValue}
            Verify={Verify}
            BookAppointment={BookAppointment}
            VerifyNumber={submitform}
            closeform={closeform}
            back={onBack}
            patientId={props.patientId}
          />
        </Grid>
        <Grid
          container
          xs={12}
          md={4}
          justify="center"
          className={classes.descriptionForm}
        >
          <TextAreaElement
            id="description"
            label="Description"
            value={props.formdata.description.value}
            setvalue={props.SetValue}
            slots={props.serviceList}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const mapstatetoprops = (state) => {
  return {
    formdata: state.appointment.data,
    slotsList: state.appointment.slotsList,
    serviceList: state.service.serviceList,
    subLoading: state.appointment.subLoading,
    subResponse: state.appointment.subResponse,
    response: state.appointment.response,
    success: state.appointment.success,
    verifyToken: state.appointment.verifyToken,
    isVerified: state.appointment.isVerified,
    tokenLoading: state.appointment.tokenLoading,
    patientId: state.appointment.patientId,
    patientLoading: state.appointment.patientLoading,
    token: state.login.token,
  };
};

const mapdispatchtoprops = (dispatch) => {
  return {
    GetServices: () => dispatch(actioncreators.GetServices_DB()),
    GetSlots: (date, serviceId, token) =>
      dispatch(actioncreators.GetSlots_DB(date, serviceId, token)),
    SetState: (key, valid, errorMessage) =>
      dispatch(actioncreators.ADSetState(key, valid, errorMessage)),
    SetValue: (entered_text, type) =>
      dispatch(actioncreators.ADSetValue(entered_text, type)),
    PostForm: (data, verifyToken) =>
      dispatch(actioncreators.ADSaveAppointment_DB(data, verifyToken)),
    ClearForm: () => dispatch(actioncreators.AClearData()),
    clearResponse: (message) => dispatch(actioncreators.Response(message)),
    GetVerifyCode: (phoneNumberData, date) =>
      dispatch(actioncreators.GetVerifyCode(phoneNumberData, date)),
    GetVerifyToken: (phoneNumberData, verificationCode) =>
      dispatch(
        actioncreators.GetVerifyToken(phoneNumberData, verificationCode)
      ),
    SetPatientId: (val) => dispatch(actioncreators.ASetPatientId(val)),
    CheckPatient: (name, phoneNumber) =>
      dispatch(actioncreators.CheckPatient_DB(name, phoneNumber)),
  };
};

export default connect(mapstatetoprops, mapdispatchtoprops)(AppointmentForm);
