import * as actiontypes from "../actions/actionTypes";

const initialState = {
  loading: false,
  response: "",
  token: null,
  isLoggedIn: false,
  data: {
    username: {
      value: "",
      validation: {
        required: true,
      },
      is_valid: true,
      error_msg: "",
      max_length: 50,
    },
    password: {
      value: "",
      validation: {
        required: true,
      },
      is_valid: true,
      error_msg: "",
      max_length: 50,
    },
  },
};

const LoginReducer = (state = initialState, action) => {
  let updated;
  switch (action.type) {
    case actiontypes.LClearAll:
      updated = initialState;
      return updated;
    case actiontypes.LLoading:
      updated = {
        ...state,
        loading: action.value,
      };
      return updated;
    case actiontypes.LResponse:
      updated = {
        ...state,
        response: action.value,
      };
      return updated;
    case actiontypes.LDsetstate:
      updated = { ...state.data[action.inputType] };
      for (const key in action.payload) {
        updated[key] = action.payload[key];
      }
      let updated_state = {
        ...state,
        data: {
          ...state.data,
          [action.inputType]: updated,
        },
      };
      return updated_state;

    case actiontypes.LDsetvalue:
      updated = {
        ...state,
        data: {
          ...state.data,
          [action.inputType]: {
            ...state.data[action.inputType],
            value: action.payload.value,
          },
        },
      };
      return updated;

    case actiontypes.LsetToken:
      updated = {
        ...state,
        token: action.value,
        isLoggedIn: true,
      };
      return updated;
    case actiontypes.LLogout:
      updated = {
        ...state,
        token: null,
        isLoggedIn: false,
      };
      return updated;
    default:
      break;
  }
  return state;
};

export default LoginReducer;
