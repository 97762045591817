import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#0779e4",
      light: "#bbdefb",
    },
  },
  mixins: {
    toolbar: {
      minHeight: 50,
    },
  },
  typography: {
    htmlFontSize: 18,
  },
});

export default theme;
