import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import { Route, Switch } from "react-router-dom";

import { connect } from "react-redux";
import * as actioncreators from "../store/actions/actionCreators";

import AppointmentScreen from "./appointmentsScreen";
import ServiceScreen from "./serviceScreen";
import AccountScreen from "./accountsScreen";
import UpdateScreen from "./updatesScreen";

import Navbar from "../components/main/appbar";

const useStyles = makeStyles((theme) => ({
  div: {
    marginLeft: theme.spacing(9),
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
    },
  },
  toolbarMargin: {
    ...theme.mixins.toolbar,
  },
}));

const MainScreen = (props) => {
  const classes = useStyles();

  useEffect(() => {
    if (!props.isLoggedIn) {
      props.history.replace("/login");
    }
    if (props.isLoggedIn) {
      props.history.replace("/appointments");
    }
  }, []);

  const logout = () => {
    props.Logout();
    props.history.replace("/login");
  };

  return (
    <div className={classes.div}>
      <Navbar logout={logout} />
      <div className={classes.toolbarMargin}></div>
      <Switch>
        <Route path="/appointments" exact component={AppointmentScreen} />
        <Route path="/services" exact component={ServiceScreen} />
        <Route path="/accounts" exact component={AccountScreen} />
        <Route path="/updates" exact component={UpdateScreen} />
      </Switch>
    </div>
  );
};

const mapstatetoprops = (state) => {
  return {
    isLoggedIn: state.login.isLoggedIn,
  };
};

const mapdispatchtoprops = (dispatch) => {
  return {
    Logout: () => dispatch(actioncreators.Logout()),
  };
};

export default connect(mapstatetoprops, mapdispatchtoprops)(MainScreen);
