import * as actiontypes from "./actionTypes";

import * as axios from "../../axios/axiosCreation";

const headers = {
  "Content-Type": "application/json;charset=UTF-8",
  "Access-Control-Allow-Origin": "*",
};

const Loading = (val) => {
  return {
    type: actiontypes.SLoading,
    value: val,
  };
};

const Response = (message) => {
  return {
    type: actiontypes.SResponse,
    value: message,
  };
};

export const SClearAll = () => {
  return {
    type: actiontypes.SClearAll,
  };
};
export const SClearData = () => {
  return {
    type: actiontypes.SClearData,
  };
};

export const SShowID = (serviceId) => {
  return {
    type: actiontypes.SShowID,
    value: serviceId,
  };
};

const ServiceLoad = (data) => {
  return {
    type: actiontypes.SServiceLoad,
    value: data,
  };
};

export const GetServices_DB = (search = "") => {
  return (dispatch) => {
    dispatch(Loading(true));
    axios.axios_ori
      .get(axios.getService, {
        headers: {
          ...headers,
        },
        params: {
          serviceName: search,
        },
      })
      .then((res) => {
        dispatch(ServiceLoad(res.data));
        dispatch(Loading(false));
      })
      .catch((err) => {
        dispatch(Response(err.response.data.error));
        dispatch(Loading(false));
      });
  };
};

///Edit and Add New Service

export const SDSetState = (key, valid, errorMessage) => {
  return {
    type: actiontypes.SDsetstate,
    inputType: key,
    payload: {
      is_valid: valid,
      error_msg: errorMessage,
    },
  };
};

export const SDSetValue = (entered_text, type) => {
  return {
    type: actiontypes.SDsetvalue,
    inputType: type,
    payload: {
      value: entered_text,
    },
  };
};

export const SDForEdit = (serviceId) => {
  return {
    type: actiontypes.SDForEdit,
    value: serviceId,
  };
};

export const SDSaveService_DB = (data, token) => {
  return (dispatch) => {
    axios.ServiceURL.post("/", data, {
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        dispatch(Response("Successfully Saved"));
      })
      .catch((err) => {
        dispatch(Response(err.response.data.error));
      });
  };
};

//Delete Services
export const SDDelete_DB = (id, token) => {
  return (dispatch) => {
    axios.ServiceURL.delete(`/${id}`, {
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
      },
    })
      .then(() => {
        dispatch(Response("Successfully Deleted"));
      })
      .catch((err) => {
        dispatch(Response(err.response.data.error));
      });
  };
};

export const SDUpdateService_DB = (id, data, token) => {
  return (dispatch) => {
    axios.ServiceURL.patch(`/${id}`, data, {
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        dispatch(Response("Successfully Updated"));
      })
      .catch((err) => {
        dispatch(Response(err.response.data.error));
      });
  };
};
