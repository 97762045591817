import { SClearAll } from "./serviceActionCreator";
import { SIClearAll } from "./serviceInfoActionCreators";
import { SAClearAll } from "./alterServiceActioncreator";
import { AClearAll } from "./appointmentActionCreators";
import { UClearAll } from "./userActionCreators";
import { LClearAll } from "./loginActionCreators";

export {
  GetServices_DB,
  SDSetState,
  SDSetValue,
  SDSaveService_DB,
  SClearAll,
  SDForEdit,
  SDDelete_DB,
  SClearData,
  SShowID,
  SDUpdateService_DB,
} from "./serviceActionCreator";

export {
  GetServicesInfo_DB,
  SIDSetState,
  SIDSetValue,
  SIDForEdit,
  SIClearAll,
  SIDSaveServiceInfo_DB,
  SIDDelete_DB,
  SIClearData,
} from "./serviceInfoActionCreators";

export {
  GetAlterServices_DB,
  SADSetState,
  SADSetValue,
  SADForEdit,
  SAClearAll,
  SADSaveAlterService_DB,
  SADDelete_DB,
  SAClearData,
} from "./alterServiceActioncreator";

export {
  LEClearAll,
  LEClearData,
  GetLeaves_DB,
  LEDSetState,
  LEDSetValue,
  LEDSaveLeave_DB,
  LEDDelete_DB,
} from "./leaveActionCreator";

export {
  GetAppointments_DB,
  ADSetState,
  ADSetValue,
  AClearAll,
  Response,
  ADSaveAppointment_DB,
  ADDelete_DB,
  AClearData,
  GetSlots_DB,
  DownloadAppointments_DB,
  GetVerifyCode,
  GetVerifyToken,
  ASetPatientId,
  CheckPatient_DB,
  Consulted,
} from "./appointmentActionCreators";

export {
  GetUsers_DB,
  UDSetState,
  UDSetValue,
  UDForEdit,
  UClearAll,
  UDSaveUsers_DB,
  UDDelete_DB,
  UClearData, //UDUpdateUsers_DB,
} from "./userActionCreators";

export {
  UPGetUpdates_DB,
  UPUploadImage,
  UPDeleteImage,
  UPSetVisible,
  UPsetVisibility,
} from "./updatesActionCreators";

export {
  LDSetState,
  LDSetValue,
  Authenticate,
  LClearAll,
  LResponse,
} from "./loginActionCreators";

export const Logout = () => {
  return (dispatch) => {
    dispatch(SClearAll());
    dispatch(SIClearAll());
    dispatch(SAClearAll());
    dispatch(AClearAll());
    dispatch(UClearAll());
    dispatch(LClearAll());
  };
};
