// actions for Services
export const SServiceLoad = "ServiceLoad";
export const SLoading = "SLoading";
export const SResponse = "SResponse";
export const SSuccess = "SSuccess";
export const SClearAll = "SClearAll";
export const SClearData = "SClearData";
export const SShowID = "SShowID";

//for new and edit service
export const SDsetstate = "SDsetstate";
export const SDsetvalue = "SDsetvalue";
export const SDForEdit = "SDForEdit";

//actions for Service Info
export const SIServiceInfoLoad = "SIServiceInfoLoad";
export const SILoading = "SILoading";
export const SIResponse = "SIResponse";
export const SISuccess = "SISuccess";
export const SIClearAll = "SIClearAll";
export const SIClearData = "SIClearData";

//for new and edit service info
export const SIDsetstate = "SIDsetstate";
export const SIDsetvalue = "SIDsetvalue";
export const SIDForEdit = "SIDForEdit";

//actions for Service Alters
export const SAAlterServicesLoad = "SAAlterServicesLoad";
export const SALoading = "SALoading";
export const SAResponse = "SAResponse";
export const SASuccess = "SASuccess";
export const SAClearAll = "SAClearAll";
export const SAClearData = "SAClearData";

//for new and edit service info
export const SADsetstate = "SADsetstate";
export const SADsetvalue = "SADsetvalue";
export const SADForEdit = "SADForEdit";

//action for Leave
export const LELeaveLoad = "LELeaveLoad";
export const LELoading = "LELoading";
export const LEResponse = "LEResponse";
export const LEClearAll = "LEClearAll";
export const LEClearData = "LEClearData";
export const LEDsetstate = "LEDsetstate";
export const LEDsetvalue = "LEDsetvalue";

//actions for Apointments
export const ApointmentsLoad = "ApointmentsLoad";
export const ALoading = "ALoading";
export const AResponse = "AResponse";
export const ASuccess = "ASuccess";
export const AClearAll = "AClearAll";
export const AClearData = "AClearData";
export const ASubLoading = "ASubLoading";
export const ASubResponse = "ASubResponse";
export const AAddSlotList = "AAddSlotList";
export const ASetVerifyToken = "ASetVerifyToken";
export const AIsVerified = "AIsVerified";
export const AtokenLoading = "AtokenLoading";
export const ASetPatientId = "ASetPatientId";
export const ASetPatientIdLoading = "ASetPatientIdLoading";
export const ASetPatientIdResponse = "ASetPatientIdResponse";

//for new and edit Appointment
export const ADsetstate = "ADsetstate";
export const ADsetvalue = "ADsetvalue";

//actions for Users
export const UserLoad = "UserLoad";
export const ULoading = "ULoading";
export const UResponse = "UResponse";
export const USuccess = "USuccess";
export const UClearAll = "UClearAll";
export const UClearData = "UClearData";

//for new and edit User
export const UDsetstate = "UDsetstate";
export const UDsetvalue = "UDsetvalue";
export const UDForEdit = "UDForEdit";

//for Login
export const LLoading = "LLoading";
export const LResponse = "LResponse";
export const LDsetstate = "LDsetstate";
export const LDsetvalue = "LDsetvalue";
export const LClearAll = "LClearAll";
export const LsetToken = "LsetToken";
export const LLogout = "LLogout";

//for Updates
export const UPLoading = "UPLoading";
export const UPResponse = "UPResponse";
export const UPSetUpdateList = "UPSetUpdateList";
export const UPsetVisibility = "UPsetVisibility";
