import * as actiontypes from "./actionTypes";

import * as axios from "../../axios/axiosCreation";

const headers = {
  "Content-Type": "application/json;charset=UTF-8",
  "Access-Control-Allow-Origin": "*",
};

const Loading = (val) => {
  return {
    type: actiontypes.ULoading,
    value: val,
  };
};

const Response = (message) => {
  return {
    type: actiontypes.UResponse,
    value: message,
  };
};
const Success = (val) => {
  return {
    type: actiontypes.USuccess,
    value: val,
  };
};

export const UClearAll = () => {
  return {
    type: actiontypes.UClearAll,
  };
};

export const UClearData = () => {
  return {
    type: actiontypes.UClearData,
  };
};

const UserLoad = (data) => {
  return {
    type: actiontypes.UserLoad,
    value: data,
  };
};

export const GetUsers_DB = (token, search) => {
  return (dispatch) => {
    dispatch(Loading(true));
    axios.UserURL.get("/", {
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
      },
      params: {
        search: search,
      },
    })
      .then((res) => {
        dispatch(UserLoad(res.data));
        dispatch(Loading(false));
      })
      .catch((err) => {
        dispatch(Loading(false));
      });
  };
};

///Edit and Add New Service Info

export const UDSetState = (key, valid, errorMessage) => {
  return {
    type: actiontypes.UDsetstate,
    inputType: key,
    payload: {
      is_valid: valid,
      error_msg: errorMessage,
    },
  };
};

export const UDSetValue = (entered_text, type) => {
  return {
    type: actiontypes.UDsetvalue,
    inputType: type,
    payload: {
      value: entered_text,
    },
  };
};

export const UDForEdit = (serviceId) => {
  return {
    type: actiontypes.UDForEdit,
    value: serviceId,
  };
};

export const UDSaveUsers_DB = (data, token) => {
  data.role = "admin";
  return (dispatch) => {
    axios.UserURL.post("/", data, {
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
      },
    })
      .then(() => {
        dispatch(Response("Successfully Saved"));
      })
      .catch((err) => {
        dispatch(Response(err.response.data.error));
      });
  };
};

//Delete Service Infos
export const UDDelete_DB = (id, token) => {
  return (dispatch) => {
    axios.UserURL.delete(`/${id}`, {
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
      },
    })
      .then(() => {
        dispatch(Response("Successfully Deleted"));
      })
      .catch((err) => {
        dispatch(Response(err.response.data.error));
      });
  };
};

/*
export const UDUpdateUsers_DB = (id, data, token) => {
  return (dispatch) => {
    axios.UserURL.patch(`/${id}`, data, {
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
      },
    })
      .then(() => {
        dispatch(Response("Successfully Updated"));
      })
      .catch((err) => {
        dispatch(Response(err.response.data.error));
      });
  };
};
*/
