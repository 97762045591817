import * as actiontypes from "./actionTypes";
import moment from "moment";

import * as axios from "../../axios/axiosCreation";

const headers = {
  "Content-Type": "application/json;charset=UTF-8",
  "Access-Control-Allow-Origin": "*",
};

const Loading = (val) => {
  return {
    type: actiontypes.ALoading,
    value: val,
  };
};

export const Response = (message) => {
  return {
    type: actiontypes.AResponse,
    value: message,
  };
};

const Success = (val) => {
  return {
    type: actiontypes.ASuccess,
    value: val,
  };
};

export const AClearAll = () => {
  return {
    type: actiontypes.AClearAll,
  };
};

export const AClearData = () => {
  return {
    type: actiontypes.AClearData,
  };
};

const AppointmentsLoad = (data) => {
  return {
    type: actiontypes.ApointmentsLoad,
    value: data,
  };
};

export const GetAppointments_DB = (token, date = {}, search = null) => {
  return (dispatch) => {
    dispatch(Loading(true));
    setTimeout(() => {
      axios.AppointmentURL.get("/", {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
        params: {
          startDate: date.sdate || moment(new Date()).format("YYYY-MM-DD"),
          endDate: date.edate,
          search: search,
        },
      })
        .then((res) => {
          dispatch(AppointmentsLoad(res.data));
          dispatch(Loading(false));
        })
        .catch((err) => {
          dispatch(Response(err.response.data.error));
          dispatch(Loading(false));
        });
    }, 500);
  };
};

// Add and Edit Appointments

export const ADSetState = (key, valid, errorMessage) => {
  return {
    type: actiontypes.ADsetstate,
    inputType: key,
    payload: {
      is_valid: valid,
      error_msg: errorMessage,
    },
  };
};

export const ADSetValue = (entered_text, type) => {
  return {
    type: actiontypes.ADsetvalue,
    inputType: type,
    payload: {
      value: entered_text,
    },
  };
};

const AIsVerified = (val) => {
  return {
    type: actiontypes.AIsVerified,
    value: val,
  };
};

const ASetVerifyToken = (token) => {
  return {
    type: actiontypes.ASetVerifyToken,
    value: token,
  };
};

export const ADSaveAppointment_DB = (data) => {
  return (dispatch) => {
    dispatch(Success(false));
    axios.AppointmentURL_User.post("/", data, {
      headers: {
        ...headers,
      },
    })
      .then(() => {
        dispatch(AClearData());
        dispatch(Response("Successfully Booked"));
        dispatch(Success(true));
      })
      .catch((err) => {
        dispatch(Response(err.response.data.error));
      });
  };
};

//Delete Appointments
export const ADDelete_DB = (id, token) => {
  return (dispatch) => {
    axios.AppointmentURL.delete(`/${id}`, {
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
      },
    })
      .then(() => {
        dispatch(Response("Successfully Deleted"));
      })
      .catch((err) => {
        dispatch(Response(err.response.data.error));
      });
  };
};

const SubLoading = (val) => {
  return {
    type: actiontypes.ASubLoading,
    value: val,
  };
};

const TokenLoading = (val) => {
  return {
    type: actiontypes.AtokenLoading,
    value: val,
  };
};

const SubResponse = (message) => {
  return {
    type: actiontypes.ASubResponse,
    value: message,
  };
};

const AddSlotList = (data) => {
  return {
    type: actiontypes.AAddSlotList,
    value: data,
  };
};

export const GetSlots_DB = (date, serviceId, token) => {
  return (dispatch) => {
    dispatch(SubLoading(true));
    axios.GetSlotsURL.get("/", {
      params: {
        date: date,
        serviceId: serviceId,
      },
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        dispatch(SubResponse(""));
        dispatch(AddSlotList(res.data));
        dispatch(SubLoading(false));
      })
      .catch((err) => {
        dispatch(SubResponse(err.response.data.error));
        dispatch(SubLoading(false));
      });
  };
};

export const DownloadAppointments_DB = (token, date = {}, search = null) => {
  return (dispatch) => {
    axios
      .axios_ori({
        url: axios.appointmentExcel,
        headers: { Authorization: `Bearer ${token}` },
        params: {
          startDate: date.sdate || moment(new Date()).format("YYYY-MM-DD"),
          endDate: date.edate,
          search: search,
        },
        method: "GET",
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${new Date().toISOString()}-appointments.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        dispatch(Response("Something went wrong while downloading this file"));
      });
  };
};

export const GetVerifyCode = (phoneNumberDate, date) => {
  return (dispatch) => {
    dispatch(Success(false));
    dispatch(AIsVerified(false));
    dispatch(TokenLoading(true));
    axios.PhoneVerificationURL.post("/", phoneNumberDate, {
      headers: {
        ...headers,
      },
      params: {
        date: date,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch(ASetVerifyToken(res.data.verifyToken));
          dispatch(Success(true));
          dispatch(AIsVerified(true));
          dispatch(TokenLoading(false));
        } else {
          dispatch(TokenLoading(false));
        }
      })
      .catch((err) => {
        dispatch(Response(err.response.data.error));
        dispatch(TokenLoading(false));
      });
  };
};

export const GetVerifyToken = (phoneNumber, verificationCode) => {
  return (dispatch) => {
    dispatch(Success(false));
    dispatch(TokenLoading(true));
    axios.PhoneVerificationURL.get("/", {
      headers: {
        ...headers,
      },
      params: {
        phoneNumber: phoneNumber,
        verificationCode: verificationCode,
      },
    })
      .then((res) => {
        dispatch(ASetVerifyToken(res.data.verifyToken));
        //dispatch(Response("Phone Number Verified"));
        dispatch(Success(true));
        dispatch(AIsVerified(true));
        dispatch(TokenLoading(false));
      })
      .catch((err) => {
        dispatch(Response(err.response.data.error));
        dispatch(TokenLoading(false));
      });
  };
};

export const ASetPatientId = (patientId) => {
  return {
    type: actiontypes.ASetPatientId,
    value: patientId,
  };
};

const PatientIdLoading = (val) => {
  return {
    type: actiontypes.ASetPatientIdLoading,
    value: val,
  };
};

export const CheckPatient_DB = (name, phoneNumber) => {
  return (dispatch) => {
    dispatch(PatientIdLoading(true));
    dispatch(ASetPatientId(null));
    axios.axios_ori
      .get(axios.checkUserURL, {
        headers: {
          ...headers,
        },
        params: {
          name: name,
          phoneNumber: phoneNumber,
        },
      })
      .then((res) => {
        dispatch(ASetPatientId(res.data.patientId));
        dispatch(PatientIdLoading(false));
      })
      .catch((err) => {
        dispatch(PatientIdLoading(false));
      });
  };
};

export const Consulted = (id, token) => {
  return (dispatch) => {
    axios.axios_ori
      .patch(
        `${axios.ConsultedURL}/${id}`,
        {},
        {
          headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        dispatch(Response("Successfully Edited"));
      })
      .catch((err) => {
        dispatch(Response(err.response.data.error));
      });
  };
};
