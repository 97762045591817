import "date-fns";
import React from "react";
import { makeStyles } from "@material-ui/styles";
import DateFnsUtils from "@date-io/date-fns";
import TextField from "@material-ui/core/TextField";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import moment from "moment";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    color: "white",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
    color: "white",
  },
  root: {
    color: "white",
  },
  dateLable: {
    color: "white",
    fontSize: 12,
  },
}));

const DatePicker = (props) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        margin="normal"
        id={props.id}
        label={props.label}
        format="MM/dd/yyyy"
        value={props.value || null}
        onChange={(date) => props.setvalue(date, props.id)}
        minDate={props.minDate ? props.minDate : null}
        maxDate={props.maxDate ? props.maxDate : new Date("01.01.2100")}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
        helperText={props.error_msg}
        error={props.valid}
        InputLabelProps={{
          shrink: moment(props.value).isValid() ? true : false,
        }}
      />
    </MuiPickersUtilsProvider>
  );
};

const SearchDate = (props) => {
  const classes = useStyles();
  return (
    <form className={classes.container} noValidate>
      <TextField
        id={props.id}
        label={props.label}
        type="date"
        value={
          moment(props.value).isValid()
            ? moment(props.value).format("YYYY-MM-DD")
            : ""
        }
        className={classes.textField}
        onChange={(e) => props.setstate(e.target.value)}
        InputLabelProps={{
          shrink: true,
          classes: {
            root: classes.dateLable,
          },
        }}
        classes={{ root: classes.root }}
        InputProps={{
          classes: { root: classes.root },
        }}
      />
    </form>
  );
};

const TimePicker = (props) => {
  let value;
  if (moment(props.value, "HH:mm").isValid()) {
    value = moment(props.value, "HH:mm");
  } else {
    value = "";
  }

  const saveTime = (time) => {
    let m_time = moment(time);
    if (m_time.isValid()) {
      props.setvalue(m_time.format("HH:mm"), props.id);
    }
  };
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardTimePicker
        margin="normal"
        id={props.id}
        label={props.label}
        value={value}
        onChange={(date) => saveTime(date)}
        KeyboardButtonProps={{
          "aria-label": "change time",
        }}
        helperText={props.error_msg}
        error={props.valid}
        InputLabelProps={{ shrink: props.value ? true : false }}
      />
    </MuiPickersUtilsProvider>
  );
};

export { DatePicker, SearchDate, TimePicker };
