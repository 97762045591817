import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import TabComponent from "../components/appointments/tabComponent";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: 3,
    padding: 5,
  },
}));

const AppointmentScreen = (props) => {
  const classes = useStyles();
  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <div className={classes.container}>
            <TabComponent />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default AppointmentScreen;
