import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { TextElement, ButtonElement } from "../../common/formElements";
import ScreenLoad from "../../common/loadingComponent";
import ResponseBox from "../../common/response";
import CustomAlert from "../../common/alert";

import { connect } from "react-redux";
import * as actioncreators from "../../../store/actions/actionCreators";

import validator from "../../validation/validator";

const useStyles = makeStyles((theme) => ({
  container: {
    width: 300,
    margin: 5,
  },
  title: {
    margin: 5,
    padding: 5,
  },
  textfield: {
    margin: 10,
    width: "90%",
  },
  button: {
    margin: 10,
    padding: 5,
  },
}));

const LoginForm = (props) => {
  const classes = useStyles();

  const submitform = () => {
    let valid = validator(props.logindata, props.setState);
    if (valid) {
      let data = {
        username: props.logindata.username.value,
        password: props.logindata.password.value,
      };
      props.Login(data);
    }
  };

  const onEnter = (e) => {
    if (e.keyCode == 13) {
      submitform();
    }
  };

  let content;
  if (props.loading) {
    content = <ScreenLoad />;
  } else if (props.response !== "") {
    content = (
      <CustomAlert text={props.response} onclick={props.clearResponse} />
    );
  } else {
    content = (
      <ButtonElement buttonText="Login" onclick={submitform} fullwidth={true} />
    );
  }

  return (
    <Paper className={classes.container}>
      <div className={classes.title}>
        <Typography variant="h5">LOGIN</Typography>
      </div>

      <Grid container>
        <Grid item xs={12}>
          <TextElement
            id="username"
            label="User Name"
            value={props.logindata.username.value}
            valid={!props.logindata.username.is_valid}
            error_msg={props.logindata.username.error_msg}
            setvalue={props.setVal}
            max_length={props.logindata.username.maxlength}
            focus={true}
          />
        </Grid>
        <Grid item xs={12}>
          <TextElement
            id="password"
            label="Password"
            type="password"
            value={props.logindata.password.value}
            valid={!props.logindata.password.is_valid}
            error_msg={props.logindata.password.error_msg}
            setvalue={props.setVal}
            max_length={props.logindata.password.maxlength}
            onenter={onEnter}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="center">
            {content}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

const mapstatetoprops = (state) => {
  return {
    logindata: state.login.data,
    loading: state.login.loading,
    response: state.login.response,
  };
};

const mapdispatchtoprops = (dispatch) => {
  return {
    setVal: (entered_text, type) =>
      dispatch(actioncreators.LDSetValue(entered_text, type)),
    setState: (key, valid, errorMessage) =>
      dispatch(actioncreators.LDSetState(key, valid, errorMessage)),
    clearResponse: () => dispatch(actioncreators.LResponse("")),
    Login: (userdata) => dispatch(actioncreators.Authenticate(userdata)),
  };
};

export { TextElement };
export default connect(mapstatetoprops, mapdispatchtoprops)(LoginForm);
