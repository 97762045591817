import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { connect } from "react-redux";
import * as actioncreators from "../../../store/actions/actionCreators";

import { TextElement, ButtonElement } from "../../common/formElements";
import validator from "../../validation/validator";

const useStyles = makeStyles((theme) => ({
  title: {
    padding: 15,
    marginBottom: 10,
  },
  buttons: {
    marginTop: 20,
  },
}));

const AccountForm = (props) => {
  const classes = useStyles();
  const is_edit = props.formdata._id.value ? true : false;
  const submitform = () => {
    let valid;
    if (is_edit) {
      valid = validator(props.formdata, props.SetState, ["password"]);
    } else {
      valid = validator(props.formdata, props.SetState);
    }

    if (valid) {
      if (is_edit) {
        let data = {
          userName: props.formdata.userName.value,
          phoneNumber: props.formdata.phoneNumber.value,
        };
        //props.UpdateForm(props.formdata._id.value, data, props.token);
      } else {
        let data = {
          userName: props.formdata.userName.value,
          phoneNumber: props.formdata.phoneNumber.value,
          password: props.formdata.password.value,
        };
        props.PostForm(data, props.token);
      }
      props.onCancel();
    }
  };

  const closeform = () => {
    props.onCancel();
  };

  return (
    <div>
      <Grid className={classes.title}>
        <Typography variant="h6">{`${
          is_edit ? "Edit" : "Add"
        } User`}</Typography>
      </Grid>

      <TextElement
        label="User Name"
        id="userName"
        focus={true}
        value={props.formdata.userName.value}
        error_msg={props.formdata.userName.error_msg}
        valid={!props.formdata.userName.is_valid}
        setvalue={props.SetValue}
        max_length={props.formdata.userName.max_length}
      />
      <Grid container>
        <Grid item xs={12} sm={6}>
          <TextElement
            label="Phone Number"
            id="phoneNumber"
            value={props.formdata.phoneNumber.value}
            error_msg={props.formdata.phoneNumber.error_msg}
            valid={!props.formdata.phoneNumber.is_valid}
            setvalue={props.SetValue}
            max_length={props.formdata.phoneNumber.max_length}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextElement
            label="password"
            id="password"
            type="password"
            //disabled={is_edit}
            value={props.formdata.password.value}
            error_msg={props.formdata.password.error_msg}
            valid={!props.formdata.password.is_valid}
            setvalue={props.SetValue}
            max_length={props.formdata.password.max_length}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={6}>
          <Grid container justify="center">
            <ButtonElement
              buttonText="cancel"
              variant="outlined"
              color="secondary"
              onclick={() => closeform()}
            />
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container justify="center">
            <ButtonElement buttonText="Save" onclick={submitform} />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const mapstatetoprops = (state) => {
  return {
    formdata: state.account.data,
    token: state.login.token,
  };
};
const mapdispatchtoprops = (dispatch) => {
  return {
    SetState: (key, valid, errorMessage) =>
      dispatch(actioncreators.UDSetState(key, valid, errorMessage)),
    SetValue: (entered_text, type) =>
      dispatch(actioncreators.UDSetValue(entered_text, type)),
    PostForm: (data, token) =>
      dispatch(actioncreators.UDSaveUsers_DB(data, token)),
    //UpdateForm: (id, data, token) =>
    //dispatch(actioncreators.UDUpdateUsers_DB(id, data, token)),
  };
};

export default connect(mapstatetoprops, mapdispatchtoprops)(AccountForm);
