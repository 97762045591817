import * as actiontypes from "./actionTypes";

import * as axios from "../../axios/axiosCreation";

const headers = {
  "Content-Type": "application/json;charset=UTF-8",
  "Access-Control-Allow-Origin": "*",
};

const Loading = (val) => {
  return {
    type: actiontypes.SALoading,
    value: val,
  };
};

const Response = (message) => {
  return {
    type: actiontypes.SAResponse,
    value: message,
  };
};

export const SAClearAll = () => {
  return {
    type: actiontypes.SAClearAll,
  };
};
export const SAClearData = () => {
  return {
    type: actiontypes.SAClearData,
  };
};

const SAAlterServicesLoad = (data) => {
  return {
    type: actiontypes.SAAlterServicesLoad,
    value: data,
  };
};

export const GetAlterServices_DB = (serviceId, token, search = "") => {
  return (dispatch) => {
    dispatch(Loading(true));
    axios.AlterServiceURL.get(`/${serviceId}`, {
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
      },
      params: {
        date: search,
      },
    })
      .then((res) => {
        dispatch(SAAlterServicesLoad(res.data));
        dispatch(Loading(false));
      })
      .catch((err) => {
        dispatch(Response(err.response.data.error));
        dispatch(Loading(false));
      });
  };
};

///Edit and Add New Service Info

export const SADSetState = (key, valid, errorMessage) => {
  return {
    type: actiontypes.SADsetstate,
    inputType: key,
    payload: {
      is_valid: valid,
      error_msg: errorMessage,
    },
  };
};

export const SADSetValue = (entered_text, type) => {
  return {
    type: actiontypes.SADsetvalue,
    inputType: type,
    payload: {
      value: entered_text,
    },
  };
};

export const SADForEdit = (serviceId) => {
  return {
    type: actiontypes.SADForEdit,
    value: serviceId,
  };
};

export const SADSaveAlterService_DB = (data, token) => {
  return (dispatch) => {
    axios.AlterServiceURL.post("/", data, {
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
      },
    })
      .then(() => {
        dispatch(Response("Successfully Saved"));
      })
      .catch((err) => {
        dispatch(Response(err.response.data.error));
      });
  };
};

//Delete Service Infos
export const SADDelete_DB = (id, token) => {
  return (dispatch) => {
    axios.AlterServiceURL.delete(`/${id}`, {
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
      },
    })
      .then(() => {
        dispatch(Response("Successfully Deleted"));
      })
      .catch((err) => {
        dispatch(Response(err.response.data.error));
      });
  };
};
