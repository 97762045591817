import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from "@material-ui/core/Grid";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import moment from "moment";

import { connect } from "react-redux";
import * as actioncreators from "../../store/actions/actionCreators";

import AppointmentItem from "./appointmentItem";
import SearchForm from "./forms/searchForm";
import ScreenLoad from "../common/loadingComponent";
import {
  ResponseDialog,
  DeleteConfirmationDialog,
} from "../common/responseDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },

  title: {
    //margin: theme.spacing(4, 0, 2),
    color: theme.palette.common.white,
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  header: {
    //backgroundColor: theme.palette.primary.main,
    overflow: "auto",
    maxHeight: 390,
    padding: 2,
  },
  headingicon: {
    color: theme.palette.common.white,
  },

  closeicon: {
    color: theme.palette.secondary.light,
  },
  heading: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: 8,
  },
  searchdiv: {
    margin: "auto",
  },
  fliterList: {
    display: "flex",
    justifyContent: "felx-start",
  },
  listroot: {
    margin: 0,
    marginTop: 2,
  },
  filterIcon: {
    padding: 0,
    marginLeft: 10,
    fontSize: 15,
    color: "white",
  },
  secondarytext: {
    padding: 0,
  },
}));

const AppointmentList = (props) => {
  const classes = useStyles();
  const [deleteId, set_deleteId] = useState(false);
  const [consultId, set_consultId] = useState(false);

  // for Search
  const [sdate, set_sdate] = useState(new Date());
  const [edate, set_edate] = useState(null);
  const [search, set_search] = useState("");

  const [filter, set_filter] = useState("");

  const [consultDialog, set_consultDialog] = useState(false);
  const [deleteDialog, set_deleteDialog] = useState(false);
  const [responseDialog, set_responseDialog] = useState(false);

  const token = props.token;
  useEffect(() => {
    if (token) {
      props.GetAppointments(token);
    }
  }, [token]);

  useEffect(() => {
    if (deleteId) {
      set_deleteDialog(true);
    }
  }, [deleteId]);
  useEffect(() => {
    if (consultId) {
      set_consultDialog(true);
    }
  }, [consultId]);

  const response = props.response;
  useEffect(() => {
    if (response !== "") {
      set_responseDialog(true);
    }
  }, [response]);

  const clearSearch = () => {
    props.GetAppointments(props.token);
    set_search("");
    set_sdate(new Date());
    set_edate(null);
  };

  const performSearch = () => {
    props.GetAppointments(props.token, { sdate, edate }, search);
  };
  const downloadExcel = () => {
    props.DownloadAppointments(props.token, { sdate, edate }, search);
  };

  const confirmDelete = (appointmentId) => {
    set_deleteId(appointmentId);
  };
  const confirmConsult = (appointmentId) => {
    set_consultId(appointmentId);
  };

  const deleteAppointment = () => {
    props.OnDelete(deleteId, props.token);
    set_deleteId(false);
    set_deleteDialog(false);
  };

  const consultAppointment = () => {
    props.Consulted(consultId, props.token);
    set_consultId(false);
    set_consultDialog(false);
  };

  const closeResponse = () => {
    props.ResponseClear("");
    props.GetAppointments(props.token, { sdate, edate }, search);
    set_deleteId(false);
    set_responseDialog(false);
  };

  const oncancelDelete = () => {
    set_deleteId(false);
    set_deleteDialog(false);
  };

  const oncancelConsult = () => {
    set_consultId(false);
    set_consultDialog(false);
  };

  const listSort = (aplist, sortOn = "") => {
    let newlist;
    if (sortOn === "date") {
      newlist = aplist.sort((a, b) => new Date(b.date) - new Date(a.date));
      return newlist;
    }
    if (sortOn === "slot") {
      newlist = aplist.sort(
        (a, b) => moment(a.slot, "HH:mm") - moment(b.slot, "HH:mm")
      );
      return newlist;
    }
    return aplist;
  };

  let content;
  if (props.loading) {
    content = <ScreenLoad />;
  } else {
    let appointmentList = listSort(props.appointmentList, filter);
    let appointments = appointmentList.map((appointment) => (
      <AppointmentItem
        id={appointment._id}
        name={appointment.patientId.name}
        patientId={appointment.patientId.patientId}
        date={appointment.date}
        slot={appointment.slot}
        phoneNumber={appointment.patientId.phoneNumber}
        street={appointment.patientId.street}
        city={appointment.patientId.city}
        zip={appointment.patientId.zip}
        description={appointment.description}
        consulted={appointment.consulted}
        delete={confirmDelete}
        consult={confirmConsult}
      />
    ));
    if (appointments.length < 1) {
      appointments = <Typography>No Results found</Typography>;
    }
    content = appointments;
  }

  return (
    <Grid item xs={12}>
      <div>
        <List dense={true} className={classes.list}>
          <SearchForm
            sdate={sdate}
            ssetDate={set_sdate}
            edate={edate}
            esetDate={set_edate}
            search={search}
            setSearch={set_search}
            onclick={performSearch}
            onclose={clearSearch}
            ondownload={downloadExcel}
          />
          <ListItem className={classes.heading}>
            <Grid container>
              <Grid item xs={12} sm={2}>
                <ListItemText primary="PatientId" />
              </Grid>
              <Grid item xs={6} sm={2}>
                <ListItemText
                  primary="Date"
                  className={classes.fliterList}
                  secondary={<ExpandMoreIcon className={classes.filterIcon} />}
                  onClick={() => set_filter("date")}
                  classes={{
                    secondary: classes.secondarytext,
                    root: classes.listroot,
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <ListItemText primary="Name" />
              </Grid>
              <Grid item xs={6} sm={1}>
                <ListItemText
                  primary="Slot"
                  className={classes.fliterList}
                  secondary={<ExpandMoreIcon className={classes.filterIcon} />}
                  onClick={() => set_filter("slot")}
                  classes={{
                    secondary: classes.secondarytext,
                    root: classes.listroot,
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <ListItemText primary="Phone Number" />
              </Grid>
            </Grid>
          </ListItem>
          <DeleteConfirmationDialog
            open={deleteDialog}
            title="Delete"
            text={"Sure wanno delete ? "}
            buttonText="Delete"
            oncancel={oncancelDelete}
            onokay={deleteAppointment}
          />
          <DeleteConfirmationDialog
            open={consultDialog}
            title="Consulted ?"
            text={"Are You Willing to change the status of the Consultation ? "}
            buttonText="Yes"
            oncancel={oncancelConsult}
            onokay={consultAppointment}
          />
          <ResponseDialog
            open={responseDialog}
            title="Response"
            text={props.response}
            onclose={closeResponse}
          />
          {content}
        </List>
      </div>
    </Grid>
  );
};

const mapstatetoprops = (state) => {
  return {
    loading: state.appointment.loading,
    response: state.appointment.response,
    success: state.appointment.success,
    appointmentList: state.appointment.appointmentList,
    token: state.login.token,
  };
};

const mapdispatchtoprops = (dispatch) => {
  return {
    GetAppointments: (token, date, search) =>
      dispatch(actioncreators.GetAppointments_DB(token, date, search)),
    ClearAll: () => dispatch(actioncreators.AClearAll()),
    OnDelete: (id, token) => dispatch(actioncreators.ADDelete_DB(id, token)),
    ClearForm: () => dispatch(actioncreators.AClearData()),
    ResponseClear: (message) => dispatch(actioncreators.Response(message)),
    DownloadAppointments: (token, date, search) =>
      dispatch(actioncreators.DownloadAppointments_DB(token, date, search)),
    Consulted: (id, token) => dispatch(actioncreators.Consulted(id, token)),
  };
};

export default connect(mapstatetoprops, mapdispatchtoprops)(AppointmentList);
