import * as actiontypes from "../actions/actionTypes";

const initialState = {
  updatesList: [],
  loading: false,
  response: "",
};

const UpdatesReducer = (state = initialState, action) => {
  let updated;
  switch (action.type) {
    case actiontypes.UPLoading:
      updated = {
        ...state,
        loading: action.value,
      };
      return updated;
    case actiontypes.UPResponse:
      updated = {
        ...state,
        response: action.value,
      };
      return updated;
    case actiontypes.UPSetUpdateList:
      updated = {
        ...state,
        updatesList: [...action.value],
      };
      return updated;
    case actiontypes.UPsetVisibility:
      let images = [...state.updatesList];
      for (const id in images) {
        let img = images[id];
        if (img._id === action.value) {
          if (img.hasOwnProperty("showtoUser")) {
            img.showtoUser = !img.showtoUser;
          } else {
            img.showtoUser = true;
          }
        }
      }
      updated = {
        ...state,
        updatesList: [...images],
      };
      return updated;

    default:
      break;
  }
  return state;
};

export default UpdatesReducer;
