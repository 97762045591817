import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import TabPanel from "../common/tabpanel";

import ServiceInfoList from "./serviceInfoList";
import ServiceAlterList from "./alterServiceList";
import LeaveList from "./leaveList";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    maxHeight: 700,
  },
});

const TabComponent = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Paper className={classes.root} variant="outlined">
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        color="primary"
      >
        <Tab label="Alter Daily Services"></Tab>
        <Tab label="Service Info"></Tab>
        <Tab label="Leaves"></Tab>
      </Tabs>
      <TabPanel value={value} index={0}>
        <ServiceAlterList />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ServiceInfoList />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <LeaveList />
      </TabPanel>
    </Paper>
  );
};

export default TabComponent;
