import * as actiontypes from "../actions/actionTypes";

const initialState = {
  appointmentList: [],
  loading: false,
  response: "",
  success: false,
  slotsList: [],
  subloading: false,
  subResponse: "",
  tokenLoading: false,
  verifyToken: null,
  isVerified: false,
  patientId: null,
  patientLoading: false,
  data: {
    _id: {
      value: "",
    },

    name: {
      value: "",
      validation: {
        required: true,
      },
      is_valid: true,
      error_msg: "",
      max_length: 50,
    },
    phoneNumber: {
      value: "",
      validation: {
        required: true,
        numeric: true,
      },
      is_valid: true,
      error_msg: "",
      max_length: 10,
    },
    street: {
      value: "",
      validation: {
        required: true,
      },
      is_valid: true,
      error_msg: "",
      max_length: 50,
    },
    city: {
      value: "",
      validation: {
        required: true,
      },
      is_valid: true,
      error_msg: "",
      max_length: 50,
    },
    zip: {
      value: "",
      validation: {
        required: true,
        numeric: true,
      },
      is_valid: true,
      error_msg: "",
      max_length: 6,
    },
    description: {
      value: "",
      max_length: 400,
    },
    date: {
      value: null,
      validation: {
        date: true,
      },
      is_valid: true,
      error_msg: "",
    },
    slot: {
      value: "",
      validation: {
        time: true,
      },
      is_valid: true,
      error_msg: "",
    },
    serviceId: {
      value: "",
      validation: {
        required: true,
      },
      is_valid: true,
      error_msg: "",
    },
  },
};

const AppointmentReducer = (state = initialState, action) => {
  let updated;
  let updated_data;
  switch (action.type) {
    case actiontypes.AClearAll:
      updated = initialState;
      return updated;

    case actiontypes.AClearData:
      updated_data = { ...state.data };
      for (const key in updated_data) {
        updated_data[key].value = "";
        updated_data[key].is_valid = true;
        updated_data[key].error_msg = "";
      }
      updated = {
        ...state,
        data: {
          ...updated_data,
        },
        verifyToken: null,
        isVerified: false,
      };
      return updated;
    case actiontypes.ApointmentsLoad:
      updated = {
        ...state,
        appointmentList: [...action.value],
      };
      return updated;
    case actiontypes.ALoading:
      updated = {
        ...state,
        loading: action.value,
      };
      return updated;
    case actiontypes.ASubLoading:
      updated = {
        ...state,
        subloading: action.value,
      };
      return updated;
    case actiontypes.AResponse:
      updated = {
        ...state,
        response: action.value,
      };
      return updated;

    case actiontypes.ASuccess:
      updated = {
        ...state,
        success: action.value,
      };
      return updated;
    case actiontypes.ASubResponse:
      updated = {
        ...state,
        subResponse: action.value,
      };
      return updated;

    case actiontypes.ADsetstate:
      updated = { ...state.data[action.inputType] };
      for (const key in action.payload) {
        updated[key] = action.payload[key];
      }
      let updated_state = {
        ...state,
        data: {
          ...state.data,
          [action.inputType]: updated,
        },
      };
      return updated_state;

    case actiontypes.ADsetvalue:
      updated = {
        ...state,
        data: {
          ...state.data,
          [action.inputType]: {
            ...state.data[action.inputType],
            value: action.payload.value,
          },
        },
      };
      return updated;

    case actiontypes.AAddSlotList:
      updated = {
        ...state,
        slotsList: [...action.value],
      };
      return updated;

    case actiontypes.AtokenLoading:
      updated = {
        ...state,
        tokenLoading: action.value,
      };
      return updated;

    case actiontypes.AIsVerified:
      updated = {
        ...state,
        isVerified: action.value,
      };
      return updated;

    case actiontypes.ASetVerifyToken:
      updated = {
        ...state,
        verifyToken: action.value,
      };
      return updated;
    case actiontypes.ASetPatientId:
      updated = {
        ...state,
        patientId: action.value,
      };
      return updated;
    case actiontypes.ASetPatientIdLoading:
      updated = {
        ...state,
        patientLoading: action.value,
      };
      return updated;
    case actiontypes.ASetPatientIdResponse:
      updated = {
        ...state,
        patientResponse: action.value,
      };
      return updated;

    default:
      break;
  }
  return state;
};

export default AppointmentReducer;
