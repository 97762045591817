import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import OtpInput from "react-otp-input";
import Alert from "@material-ui/lab/Alert";

import LoadingComponent from "./loadingComponent";

const ResponseDialog = (props) => {
  return (
    <div>
      <Dialog
        open={props.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ minWidth: 300 }}
          >
            {props.text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => props.onclose()} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const DeleteConfirmationDialog = (props) => {
  return (
    <div>
      <Dialog
        open={props.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ minWidth: 300 }}
          >
            {props.text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => props.oncancel()}
            color="secondary"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={() => props.onokay()}
            color="primary"
            autoFocus
            variant="contained"
          >
            {props.buttonText ? props.buttonText : "Delete"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const PhoneNumberVerificationDialog = (props) => {
  let content;
  if (props.loading) {
    content = <LoadingComponent />;
  } else if (props.isVerified) {
    content = (
      <Alert severity="success">
        Your Phone Number is Verified, Now You an Book Appointment
      </Alert>
    );
  } else {
    content = (
      <div style={{ minWidth: 300, justifyContent: "center", display: "flex" }}>
        <OtpInput
          onChange={(otp) => props.setvalue(otp)}
          value={props.value}
          numInputs={4}
          separator={<span>-</span>}
          inputStyle={{
            width: 50,
            height: 50,
          }}
        />
      </div>
    );
  }
  return (
    <div>
      <Dialog
        open={props.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>{content}</DialogContent>
        <DialogActions>
          {props.isVerified ? null : (
            <Button onClick={() => props.getcode()} color="primary">
              Resend Code
            </Button>
          )}

          <Button
            onClick={() => props.oncancel()}
            color="secondary"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={() => props.onokay()}
            color="primary"
            autoFocus
            variant="contained"
          >
            {props.isVerified ? "Book Appointment" : "Apply Code"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export {
  ResponseDialog,
  DeleteConfirmationDialog,
  PhoneNumberVerificationDialog,
};
