import * as actiontypes from "../actions/actionTypes";

const initialState = {
  alterServicesList: [],
  loading: false,
  response: "",
  data: {
    id: {
      value: "",
    },
    date: {
      value: "",
      validation: {
        date: true,
      },
      is_valid: true,
      error_msg: "",
    },
    duration: {
      value: "",
      validation: {
        required: true,
        numneric: true,
        range: [10, 120],
      },
      is_valid: true,
      error_msg: "",
    },
    startTime: {
      value: "",
      validation: {
        time: true,
      },
      is_valid: true,
      error_msg: "",
    },
    endTime: {
      value: "",
      validation: {
        time: true,
      },
      is_valid: true,
      error_msg: "",
    },
  },
};

const ServiceAlterReducer = (state = initialState, action) => {
  let updated;
  let updated_data;
  switch (action.type) {
    case actiontypes.SAClearAll:
      updated = initialState;
      return updated;

    case actiontypes.SAClearData:
      updated_data = { ...state.data };
      for (const key in updated_data) {
        updated_data[key].value = "";
      }
      updated = {
        ...state,
        data: {
          ...updated_data,
        },
      };
      return updated;

    case actiontypes.SAAlterServicesLoad:
      updated = {
        ...state,
        alterServicesList: [...action.value],
      };
      return updated;
    case actiontypes.SALoading:
      updated = {
        ...state,
        loading: action.value,
      };
      return updated;
    case actiontypes.SAResponse:
      updated = {
        ...state,
        response: action.value,
      };
      return updated;

    case actiontypes.SADsetstate:
      updated = { ...state.data[action.inputType] };
      for (const key in action.payload) {
        updated[key] = action.payload[key];
      }
      let updated_state = {
        ...state,
        data: {
          ...state.data,
          [action.inputType]: updated,
        },
      };
      return updated_state;

    case actiontypes.SADsetvalue:
      updated = {
        ...state,
        data: {
          ...state.data,
          [action.inputType]: {
            ...state.data[action.inputType],
            value: action.payload.value,
          },
        },
      };
      return updated;

    case actiontypes.SADForEdit:
      let alterService = state.alterServicesList.filter(
        (alterService) => alterService.id === action.value
      );
      updated_data = { ...state.data };
      for (const key in alterService[0]) {
        updated_data[key].value = alterService[0][key];
      }
      updated = {
        ...state,
        data: {
          ...updated_data,
        },
      };
      return updated;
    default:
      break;
  }
  return state;
};

export default ServiceAlterReducer;
