import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Gallery from "react-grid-gallery";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";

import { connect } from "react-redux";
import * as actioncreators from "../../store/actions/actionCreators";

import UpdateForm from "./forms/updateForm";
import ScreenLoad from "../common/loadingComponent";
import {
  ResponseDialog,
  DeleteConfirmationDialog,
} from "../common/responseDialog";

const captionStyle = {
  position: "absolute",
  right: "5px",
  bottom: "5px",
  color: "white",
  padding: "2px",
  zIndex: "2",
};

const iconStyle = {
  color: "#ff4081",
};

class UpdateImagesList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      deleteId: null,
      responseOpen: false,
      deleteConfirmation: false,
      visibleList: [],
    };

    this.onSelectImage = this.onSelectImage.bind(this);
  }

  componentDidMount() {
    if (this.props.token) {
      this.props.GetUpdateList(this.props.token);
    }
  }

  componentDidUpdate(prevprops, prevstate) {
    if (prevprops.response !== this.props.response) {
      if (this.props.response !== "") {
        this.setState({ responseOpen: true });
      }
    }
    if (
      prevstate.responseOpen !== this.state.responseOpen &&
      !this.state.responseOpen
    ) {
      this.props.GetUpdateList(this.props.token);
    }
  }

  onSelectImage(index) {
    var images = this.props.images.slice();
    var img = images[index];
    this.props.setVisibility(img._id);
  }

  saveVisiblity = () => {
    let imageIds = [];
    for (const id in this.props.images) {
      const image = this.props.images[id];
      if (image.hasOwnProperty("showtoUser")) {
        if (image["showtoUser"]) {
          imageIds.push(image._id);
        }
      }
    }
    this.props.SetVisible(imageIds, this.props.token);
  };

  confirmDelete = (id) => {
    this.setState({ deleteId: id, deleteConfirmation: true });
  };

  deleteUpdate = () => {
    this.props.OnDelete(this.state.deleteId, this.props.token);
    this.setState({ deleteId: null, deleteConfirmation: false });
  };

  closeResponse = () => {
    this.setState({ deleteId: false, responseOpen: false });
  };
  oncancelDelete = () => {
    this.setState({ deleteId: null, deleteConfirmation: false });
  };
  render() {
    let content;
    if (this.props.loading) {
      content = <ScreenLoad />;
    } else {
      content = (
        <Gallery
          images={this.props.images.map((update) => {
            const image = {
              key: update._id,
              src: "/api/" + update.imageUrl,
              thumbnail: "/api/" + update.imageUrl,
              thumbnailWidth: 320,
              thumbnailHeight: 240,
              caption: "Batman",
              isSelected: update.showtoUser,
              thumbnailCaption: (
                <IconButton
                  aria-label="delete"
                  onClick={() => this.confirmDelete(update._id)}
                  style={captionStyle}
                >
                  <DeleteIcon style={iconStyle} />
                </IconButton>
              ),
            };

            return image;
          })}
          onSelectImage={this.onSelectImage}
          lightboxWidth={1536}
        />
      );
    }
    return (
      <>
        <UpdateForm setvisibility={this.saveVisiblity} />
        <div
          style={{
            display: "block",
            minHeight: "1px",
            width: "100%",
            border: "1px solid #ddd",
            overflow: "auto",
          }}
        >
          <DeleteConfirmationDialog
            open={this.state.deleteConfirmation}
            title="Delete"
            text={"Sure wanno delete ? "}
            oncancel={this.oncancelDelete}
            onokay={this.deleteUpdate}
          />
          <ResponseDialog
            open={this.state.responseOpen}
            title="Update"
            text={this.props.response}
            onclose={this.closeResponse}
          />
          {content}
        </div>
      </>
    );
  }
}

UpdateImagesList.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      thumbnail: PropTypes.string.isRequired,
      srcset: PropTypes.array,
      caption: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      thumbnailWidth: PropTypes.number.isRequired,
      thumbnailHeight: PropTypes.number.isRequired,
      isSelected: PropTypes.bool,
      tags: PropTypes.string.isRequired, //id
    })
  ).isRequired,
};

const mapstatetoprops = (state) => {
  return {
    loading: state.update.loading,
    response: state.update.response,
    images: state.update.updatesList,
    token: state.login.token,
  };
};

const mapdispatchtoprops = (dispatch) => {
  return {
    GetUpdateList: (token) => dispatch(actioncreators.UPGetUpdates_DB(token)),
    OnDelete: (id, token) => dispatch(actioncreators.UPDeleteImage(id, token)),
    setVisibility: (id) => dispatch(actioncreators.UPsetVisibility(id)),
    SetVisible: (updateIds, token) =>
      dispatch(actioncreators.UPSetVisible(updateIds, token)),
  };
};

export default connect(mapstatetoprops, mapdispatchtoprops)(UpdateImagesList);
