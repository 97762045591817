const validator = (data, setState, exclusion = ["_id"]) => {
  let overall_valid = true;
  for (const key in data) {
    if (exclusion.includes(key)) {
      continue;
    }
    let validations = data[key].validation;
    let valid = true;
    let errorMessage = "";
    for (const validation in validations) {
      if (validation === "requiredNonString") {
        if (data[key].value) {
          valid &= true;
        } else {
          valid = false;
          overall_valid &= false;
          errorMessage = "This Field is Required";
          break;
        }
      }
      if (validation === "required") {
        if (data[key].value.trim().length > 0) {
          valid &= true;
        } else {
          valid = false;
          overall_valid &= false;
          errorMessage = "This Field is Required";
          break;
        }
      }
      if (validation === "numeric") {
        const pattern = new RegExp("^[0-9]+$");
        if (pattern.test(data[key].value)) {
          valid &= true;
        } else {
          valid = false;
          overall_valid &= false;
          errorMessage = "This Field can contain only Digits";
          break;
        }
      }
      if (validation === "range") {
        if (
          data[key].value >= validations[validation][0] &&
          data[key].value < validations[validation][1]
        ) {
          valid &= true;
        } else {
          valid = false;
          overall_valid &= false;
          errorMessage = "This Field can only be between 10mins and 120mins";
          break;
        }
      }
    }
    if (!valid) {
      setState(key, valid, errorMessage);
    } else {
      setState(key, true, "");
    }
  }
  if (overall_valid) {
    return true;
  }
  return false;
};

export default validator;
