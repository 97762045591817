import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import LoginLogo from "../components/login/logo";
import LoginForm from "../components/login/forms/loginForm";

import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexFlow: "column",
    height: "100vh",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const LoginScreen = (props) => {
  const classes = useStyles();

  const isLoggedIn = props.is_loggedIn;
  const history = props.history;

  useEffect(() => {
    if (isLoggedIn) {
      history.push("/");
    }
  }, [isLoggedIn, history]);

  return (
    <div className={classes.container}>
      <Grid container justify="center">
        <LoginLogo />
        <LoginForm />
      </Grid>
    </div>
  );
};

const mapstatetoprops = (state) => {
  return {
    is_loggedIn: state.login.isLoggedIn,
  };
};

export default connect(mapstatetoprops)(LoginScreen);
