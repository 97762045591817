import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import TabPanel from "../common/tabpanel";

import AppointmentList from "./appointmentList";
import AppointmentForm from "./forms/newAppointmentForm";

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: 700,
  },
  tabpanel1: {
    backgroundColor: theme.palette.grey[300],
    border: "2px solid white",
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
  },
}));

const TabComponent = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Paper className={classes.root} variant="outlined">
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        color="primary"
      >
        <Tab label="Appointments"></Tab>
        <Tab label="Add Appointments"></Tab>
      </Tabs>
      <TabPanel value={value} index={0} className={classes.tabpanel1}>
        <AppointmentList set_value={setValue} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <AppointmentForm set_value={setValue} />
      </TabPanel>
    </Paper>
  );
};

export default TabComponent;
