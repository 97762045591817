import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

const ValidationTextField = withStyles((theme) => ({
  root: {
    "& input:valid + fieldset": {
      borderColor: theme.palette.primary.light,
      borderWidth: 2,
    },
    "& input:invalid + fieldset": {
      borderColor: "red",
      borderWidth: 2,
    },
    "& input:valid:focus + fieldset": {
      borderLeftWidth: 6,
      padding: "4px !important", // override inline-style
    },
  },
}))(TextField);

const useStyles = makeStyles((theme) => ({
  textfield: {
    margin: 10,
    width: (props) => (props.width ? props.width : "90%"),
  },
  button: {
    margin: (props) => (props.nomargin ? 0 : 10),
    padding: (props) => (props.nopadding ? 0 : 5),
    width: (props) => (props.fullwidth ? "100%" : 150),
  },
  selectEmpty: {
    width: 240,
  },
  textarea: {
    width: "100%",
    height: 100,
  },
}));

const TextElement = (props) => {
  const classes = useStyles(props);
  return (
    <ValidationTextField
      variant="outlined"
      autoFocus={props.focus}
      margin="dense"
      id={props.id}
      className={classes.textfield}
      label={props.label}
      value={props.value}
      helperText={props.error_msg}
      type={props.type}
      error={props.valid}
      onChange={(e) => props.setvalue(e.target.value, props.id)}
      inputProps={{ maxLength: props.max_length }}
      onKeyDown={props.onenter ? (e) => props.onenter(e) : undefined}
      disabled={props.disabled ? true : false}
    />
  );
};

const SelectElement = (props) => {
  const classes = useStyles();
  let options;
  options = props.values.map((value) => {
    return <option value={value}>{value}</option>;
  });
  return (
    <FormControl className={classes.formControl} error={props.valid}>
      <NativeSelect
        value={props.value}
        onChange={(e) => props.setvalue(e.target.value, props.id)}
        name={props.label}
        id={props.id}
        className={classes.selectEmpty}
      >
        <option value="">None</option>
        {options}
      </NativeSelect>
      <FormHelperText>{props.error_msg}</FormHelperText>
    </FormControl>
  );
};

const ServiceSelect = (props) => {
  const classes = useStyles();
  let options;
  options = props.values.map((value) => {
    return <option value={value._id}>{value.serviceName}</option>;
  });
  return (
    <FormControl
      className={classes.formControl}
      error={props.valid}
      disabled={props.disabled}
    >
      <NativeSelect
        value={props.value}
        onChange={(e) => props.setvalue(e.target.value, props.id)}
        name={props.label}
        id={props.id}
        className={classes.selectEmpty}
      >
        <option value="">None</option>
        {options}
      </NativeSelect>
      <FormHelperText>{props.error_msg}</FormHelperText>
    </FormControl>
  );
};

const TextAreaElement = (props) => {
  const classes = useStyles();
  return (
    <TextareaAutosize
      id={props.id}
      rowsMax={5}
      aria-label={props.description}
      placeholder="Enter Descriptions Here"
      value={props.value}
      className={classes.textarea}
      onChange={(e) => props.setvalue(e.target.value, props.id)}
      style={{
        height: "100%",
        width: "100%",
        minHeight: 100,
        padding: 10,
        fontSize: "1rem",
      }}
    />
  );
};

const ButtonElement = (props) => {
  const classes = useStyles(props);
  return (
    <div className={classes.button}>
      <Button
        variant={props.variant ? props.variant : "contained"}
        color={props.color ? props.color : "primary"}
        disableElevation
        fullWidth
        onClick={() => props.onclick()}
      >
        {props.buttonText}
      </Button>
    </div>
  );
};

export {
  TextElement,
  SelectElement,
  ServiceSelect,
  TextAreaElement,
  ButtonElement,
};
