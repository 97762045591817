import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import logo from "../../assets/Logo.png";

const useStyles = makeStyles((theme) => ({
  container: {
    width: 200,
    height: 250,
    margin: 5,
  },
  logo: {
    display: "flex",
    height: "78%",
    margin: 5,
  },
  image: {
    objectFit: "contain",
    flex: 1,
    width: "100%",
  },
  title: {
    margin: 5,
    padding: 5,
    backgroundColor: "#ccf",
  },
}));

const LoginLogo = (props) => {
  const classes = useStyles();
  return (
    <Paper className={classes.container}>
      <div className={classes.logo}>
        <img className={classes.image} src={logo} />
      </div>
      <div className={classes.title}>
        <Typography variant="h5">Mitra Clinic</Typography>
      </div>
    </Paper>
  );
};

export default LoginLogo;
