import * as actiontypes from "./actionTypes";

import * as axios from "../../axios/axiosCreation";
const headers = {
  "Content-Type": "application/json;charset=UTF-8",
  "Access-Control-Allow-Origin": "*",
};

const Loading = (val) => {
  return {
    type: actiontypes.SILoading,
    value: val,
  };
};

const Response = (message) => {
  return {
    type: actiontypes.SIResponse,
    value: message,
  };
};

export const SIClearAll = () => {
  return {
    type: actiontypes.SIClearAll,
  };
};

export const SIClearData = () => {
  return {
    type: actiontypes.SIClearData,
  };
};

const SIServiceInfoLoad = (data) => {
  return {
    type: actiontypes.SIServiceInfoLoad,
    value: data,
  };
};

export const GetServicesInfo_DB = (serviceId, token, search = "") => {
  return (dispatch) => {
    dispatch(Loading(true));
    axios.ServiceInfoURL.get(`/${serviceId}`, {
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
      },
      params: {
        fromDate: search,
      },
    })
      .then((res) => {
        dispatch(SIServiceInfoLoad(res.data));
        dispatch(Loading(false));
      })
      .catch((err) => {
        dispatch(Response(err.response.data.error));
        dispatch(Loading(false));
      });
  };
};

///Edit and Add New Service Info

export const SIDSetState = (key, valid, errorMessage) => {
  return {
    type: actiontypes.SIDsetstate,
    inputType: key,
    payload: {
      is_valid: valid,
      error_msg: errorMessage,
    },
  };
};

export const SIDSetValue = (entered_text, type) => {
  return {
    type: actiontypes.SIDsetvalue,
    inputType: type,
    payload: {
      value: entered_text,
    },
  };
};

export const SIDForEdit = (serviceId) => {
  return {
    type: actiontypes.SIDForEdit,
    value: serviceId,
  };
};

export const SIDSaveServiceInfo_DB = (data, token) => {
  return (dispatch) => {
    axios.ServiceInfoURL.post("/", data, {
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
      },
    })
      .then(() => {
        dispatch(Response("Successfully Saved"));
      })
      .catch((err) => {
        dispatch(Response(err.response.data.error));
      });
  };
};

//Delete Service Infos
export const SIDDelete_DB = (id, token) => {
  return (dispatch) => {
    axios.ServiceInfoURL.delete(`/${id}`, {
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
      },
    })
      .then(() => {
        dispatch(Response("Successfully Deleted"));
      })
      .catch((err) => {
        dispatch(Response(err.response.data.error));
      });
  };
};
