import * as actiontypes from "./actionTypes";
import * as axios from "../../axios/axiosCreation";

const Loading = (val) => {
  return {
    type: actiontypes.LLoading,
    value: val,
  };
};

export const LResponse = (message) => {
  return {
    type: actiontypes.LResponse,
    value: message,
  };
};

export const LClearAll = () => {
  return {
    type: actiontypes.LClearAll,
  };
};

export const LDSetState = (key, valid, errorMessage) => {
  return {
    type: actiontypes.LDsetstate,
    inputType: key,
    payload: {
      is_valid: valid,
      error_msg: errorMessage,
    },
  };
};

export const LDSetValue = (entered_text, type) => {
  return {
    type: actiontypes.LDsetvalue,
    inputType: type,
    payload: {
      value: entered_text,
    },
  };
};

const LsetToken = (token) => {
  return {
    type: actiontypes.LsetToken,
    value: token,
  };
};

export const Authenticate = (data) => {
  return (dispatch) => {
    dispatch(Loading(true));
    axios.LoginURL.post("/", data)
      .then((res) => {
        dispatch(LsetToken(res.data.token));
        dispatch(Loading(false));
      })
      .catch((err) => {
        dispatch(LResponse(err.response.data.error));
        dispatch(Loading(false));
      });
  };
};

export const CheckLoginStatus = () => {
  return (dispatch) => {
    const token = localStorage.getItem("Token");
    if (token) {
      dispatch(LsetToken(token));
    }
  };
};

//Log out is defined in actionCreators.js
