import * as actiontypes from "../actions/actionTypes";

const initialState = {
  serviceInfoList: [],
  loading: false,
  response: "",
  data: {
    _id: {
      value: null,
    },
    fromDate: {
      value: null,
      validation: {
        date: true,
      },
      is_valid: true,
      error_msg: "",
    },
    duration: {
      value: "",
      validation: {
        required: true,
        numneric: true,
        range: [10, 120],
      },
      is_valid: true,
      error_msg: "",
      max_length: 3,
    },
    startTime: {
      value: "",
      validation: {
        time: true,
      },
      is_valid: true,
      error_msg: "",
    },
    endTime: {
      value: "",
      validation: {
        time: true,
      },
      is_valid: true,
      error_msg: "",
    },
  },
};

const ServiceInfoReducer = (state = initialState, action) => {
  let updated;
  let updated_data;
  switch (action.type) {
    case actiontypes.SIClearAll:
      updated = initialState;
      return updated;
    case actiontypes.SIClearData:
      updated_data = { ...state.data };
      for (const key in updated_data) {
        updated_data[key].value = "";
      }
      updated = {
        ...state,
        data: {
          ...updated_data,
        },
      };
      return updated;
    case actiontypes.SIServiceInfoLoad:
      updated = {
        ...state,
        serviceInfoList: [...action.value],
      };
      return updated;
    case actiontypes.SILoading:
      updated = {
        ...state,
        loading: action.value,
      };
      return updated;
    case actiontypes.SIResponse:
      updated = {
        ...state,
        response: action.value,
      };
      return updated;

    case actiontypes.SIDsetstate:
      updated = { ...state.data[action.inputType] };
      for (const key in action.payload) {
        updated[key] = action.payload[key];
      }
      let updated_state = {
        ...state,
        data: {
          ...state.data,
          [action.inputType]: updated,
        },
      };
      return updated_state;

    case actiontypes.SIDsetvalue:
      updated = {
        ...state,
        data: {
          ...state.data,
          [action.inputType]: {
            ...state.data[action.inputType],
            value: action.payload.value,
          },
        },
      };
      return updated;

    case actiontypes.SIDForEdit:
      let service = state.serviceInfoList.filter(
        (serviceInfo) => serviceInfo._id === action.value
      );
      updated_data = { ...state.data };
      for (const key in service[0]) {
        updated_data[key].value = service[0][key];
      }
      updated = {
        ...state,
        data: {
          ...updated_data,
        },
      };
      return updated;
    default:
      break;
  }
  return state;
};

export default ServiceInfoReducer;
