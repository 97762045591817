import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  deleteicon: {
    color: theme.palette.secondary.light,
  },
  consulticon: {
    color: theme.palette.primary.main,
  },
  div: {
    display: "flex",
    justifyContent: "space-between",
  },
  icons: {
    padding: 5,
    marginRight: 15,
  },
}));

const AppointmentItem = (props) => {
  const classes = useStyles();

  return (
    <ExpansionPanel id={props.id}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
      >
        <Grid container>
          <Grid item xs={12} sm={2}>
            <Typography align="left">{props.patientId}</Typography>
          </Grid>
          <Grid item xs={6} sm={2}>
            <Typography align="left">
              {new Date(props.date).toDateString()}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Typography align="left">{props.name}</Typography>
          </Grid>
          <Grid item xs={6} sm={1}>
            <Typography align="left">{props.slot}</Typography>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Typography align="left">{props.phoneNumber}</Typography>
          </Grid>
        </Grid>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Divider />
        <Grid container>
          <Grid item xs={6}>
            <div className={classes.div}>
              <Typography>Address: </Typography>
              <Typography>
                {`${props.street}, ${props.city}, ${props.zip}`}
              </Typography>
              <Typography>Description: </Typography>
              <Typography>{props.description}</Typography>
            </div>
          </Grid>
          <Grid container xs={6} justify="flex-end">
            <div className={classes.div}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.consulted}
                    onChange={() => props.consult(props.id)}
                    name="consult"
                    color="primary"
                  />
                }
                label="Consulted"
              />

              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => props.delete(props.id)}
              >
                <DeleteIcon className={classes.deleteicon} />
              </IconButton>
            </div>
          </Grid>
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default AppointmentItem;
