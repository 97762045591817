import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";

import { connect } from "react-redux";
import * as actioncreators from "../../store/actions/actionCreators";

import Paper from "@material-ui/core/Paper";
import SearchTile from "../common/searchBar";
import TabPanel from "../common/tabpanel";
import Serviceform from "./forms/serviceForm";
import ScreenLoad from "../common/loadingComponent";
import {
  ResponseDialog,
  DeleteConfirmationDialog,
} from "../common/responseDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  listitem: {
    marginTop: 5,
    backgroundColor: theme.palette.background.paper,
    border: `2px solid ${theme.palette.primary.light}`,
    borderRadius: 3,
    "&:hover": {
      //backgroundColor: theme.palette.primary.light,
      border: `2px solid ${theme.palette.primary.main}`,
      //backgroundColor: theme.palette.primary.main,
      //color: theme.palette.common.white,
    },
  },
  activeListItem: {
    marginTop: 5,
    backgroundColor: theme.palette.background.paper,
    border: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderRadius: 3,
  },
  title: {
    //margin: theme.spacing(4, 0, 2),
    color: theme.palette.common.white,
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  header: {
    //backgroundColor: theme.palette.primary.main,
    overflow: "auto",
    maxHeight: 390,
    padding: 2,
  },
  headingicon: {
    color: theme.palette.common.white,
  },
  deleteicon: {
    color: theme.palette.secondary.light,
  },
  editicon: {
    color: theme.palette.primary.main,
  },
  activeEditIcon: {
    color: theme.palette.common.white,
  },
  closeicon: {
    color: theme.palette.secondary.light,
  },
  heading: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
    padding: 8,
  },
  searchdiv: {
    margin: "auto",
  },
}));

const ServiceItem = (props) => {
  const classes = useStyles();
  const [visible, set_visible] = useState(false);

  return (
    <div
      className={
        props.showId === props.id ? classes.activeListItem : classes.listitem
      }
      onMouseEnter={() => set_visible(true)}
      onMouseLeave={() => set_visible(false)}
      id={props.id}
      onClick={() => props.onclick(props.id)}
    >
      <ListItem>
        <ListItemText primary={props.text} />
        {visible ? (
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              aria-label="edit"
              onClick={() => props.forEdit(props.id)}
            >
              <EditIcon
                className={
                  props.showId === props.id
                    ? classes.activeEditIcon
                    : classes.editicon
                }
              />
            </IconButton>
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={() => props.delete(props.id)}
            >
              <DeleteIcon className={classes.deleteicon} />
            </IconButton>
          </ListItemSecondaryAction>
        ) : null}
      </ListItem>
    </div>
  );
};

const ServiceList = (props) => {
  const classes = useStyles();
  const [search_visible, set_search_visible] = useState(false);
  const [value, set_value] = useState(0);
  const [deleteId, set_deleteId] = useState(false);
  const [search, set_search] = useState();

  const [deleteDialog, set_deleteDialog] = useState(false);
  const [responseDialog, set_responseDialog] = useState(false);

  useEffect(() => {
    props.GetServices();
  }, []);

  useEffect(() => {
    if (deleteId) {
      set_deleteDialog(true);
    }
  }, [deleteId]);

  const response = props.response;
  useEffect(() => {
    if (response !== "") {
      set_responseDialog(true);
    }
  }, [response]);

  const closeSearch = () => {
    set_search_visible(false);
    props.GetServices();
    set_search("");
  };
  const performSearch = (e) => {
    if (e.keyCode == 13) {
      props.GetServices(search);
    }
  };

  const openForm = () => {
    props.ClearForm();
    set_value(1);
  };
  const onCancelForm = () => {
    props.ClearForm();
    set_value(0);
  };

  const onEdit = (serviceId) => {
    props.ForEdit(serviceId);
    set_value(1);
  };

  const confirmDelete = (serviceId) => {
    set_deleteId(serviceId);
    set_deleteDialog(false);
  };

  const deleteService = () => {
    props.OnDelete(deleteId, props.token);
    set_deleteId(false);
    set_deleteDialog(false);
  };

  const closeResponse = () => {
    props.ClearAll();
    props.GetServices();
    set_value(0);
    set_deleteId(false);
    set_responseDialog(false);
  };

  const oncancelDelete = () => {
    set_deleteId(false);
    set_deleteDialog(false);
  };

  const fullSearch = (
    <>
      <div className={classes.searchdiv}>
        <SearchTile
          value={search}
          setstate={set_search}
          onclick={performSearch}
        />
      </div>
      <IconButton edge="end" aria-label="search" onClick={() => closeSearch()}>
        <CloseIcon className={classes.closeicon} />
      </IconButton>
    </>
  );

  const search_button = (
    <IconButton
      edge="end"
      aria-label="search"
      onClick={() => set_search_visible(true)}
    >
      <SearchIcon className={classes.headingicon} />
    </IconButton>
  );

  let content;

  if (props.loading) {
    content = <ScreenLoad />;
  } else {
    let services = props.serviceList.map((service) => (
      <ServiceItem
        id={service._id}
        text={service.serviceName}
        forEdit={onEdit}
        delete={confirmDelete}
        onclick={props.OpenTab}
        showId={props.showId}
      />
    ));
    if (props.serviceList.length < 1) {
      services = <Typography>No Results found</Typography>;
    }
    content = (
      <>
        <TabPanel value={value} index={0}>
          {services}
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Serviceform onCancel={onCancelForm} />
        </TabPanel>
      </>
    );
  }

  return (
    <Paper variant="outlined" className={classes.header}>
      <Grid item xs={12}>
        <div>
          <List dense={true} className={classes.list}>
            <ListItem className={classes.heading}>
              <ListItemText primary="Services" />
              <ListItemSecondaryAction style={{ display: "flex" }}>
                {value === 0
                  ? search_visible
                    ? fullSearch
                    : search_button
                  : null}

                <IconButton edge="end" aria-label="add">
                  <AddIcon
                    className={classes.headingicon}
                    onClick={() => openForm()}
                  />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <DeleteConfirmationDialog
              open={deleteDialog}
              title="Delete"
              text={"Sure wanno delete ? "}
              oncancel={oncancelDelete}
              onokay={deleteService}
            />
            <ResponseDialog
              open={responseDialog}
              title="Update"
              text={props.response}
              onclose={closeResponse}
            />
            {content}
          </List>
        </div>
      </Grid>
    </Paper>
  );
};

const mapstatetoprops = (state) => {
  return {
    loading: state.service.loading,
    success: state.service.success,
    serviceList: state.service.serviceList,
    response: state.service.response,
    showId: state.service.showId,
    token: state.login.token,
  };
};

const mapdispatchtoprops = (dispatch) => {
  return {
    GetServices: (search) => dispatch(actioncreators.GetServices_DB(search)),
    ClearAll: () => dispatch(actioncreators.SClearAll()),
    ForEdit: (id) => dispatch(actioncreators.SDForEdit(id)),
    OnDelete: (id, token) => dispatch(actioncreators.SDDelete_DB(id, token)),
    OpenTab: (id) => dispatch(actioncreators.SShowID(id)),
    ClearForm: () => dispatch(actioncreators.SClearData()),
  };
};

export default connect(mapstatetoprops, mapdispatchtoprops)(ServiceList);
