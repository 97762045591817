import moment from "moment";

const DateTimeValidator = (
  data,
  setState,
  inclusion = ["fromDate", "date", "startTime", "endTime", "slot"]
) => {
  let overall_valid = true;
  for (const key in data) {
    if (!inclusion.includes(key)) {
      continue;
    }
    let validations = data[key].validation;
    let valid = true;
    let errorMessage = "";
    for (const validation in validations) {
      if (validation === "date") {
        if (moment(data[key].value).isValid()) {
          valid &= true;
        } else {
          valid = false;
          overall_valid &= false;
          errorMessage = "Not A Valid Date";
          break;
        }
      }
      if (validation === "time") {
        if (moment(data[key].value, "HH:mm").isValid()) {
          valid &= true;
        } else {
          valid = false;
          overall_valid &= false;
          errorMessage = "Not A Valid Time";
          break;
        }
      }
    }
    if (!valid) {
      setState(key, valid, errorMessage);
    } else {
      setState(key, true, "");
    }
  }
  if (overall_valid) {
    return true;
  }
  return false;
};

export default DateTimeValidator;
