import React from "react";
import Grid from "@material-ui/core/Grid";
import { ButtonElement } from "./formElements";

const DoubleButton = (props) => {
  return (
    <Grid container style={{ width: "100%" }}>
      <Grid container xs={12} sm={6}>
        <ButtonElement
          buttonText={props.leftText}
          variant="contained"
          color="secondary"
          fullwidth={true}
          onclick={props.leftClick}
        />
      </Grid>
      <Grid container xs={12} sm={6}>
        <ButtonElement
          buttonText={props.rightText}
          variant="contained"
          color="primary"
          fullwidth={true}
          onclick={props.rightClick}
        />
      </Grid>
    </Grid>
  );
};

export default DoubleButton;
