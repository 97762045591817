import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InsertInvitationIcon from "@material-ui/icons/InsertInvitation";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AccountCircle from "@material-ui/icons/AccountCircle";
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt";
import Hidden from "@material-ui/core/Hidden";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  toolbarMargin: {
    ...theme.mixins.toolbar,
  },
  drawermini: {
    width: 240,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: 240,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  listdiv: {
    backgroundColor: theme.palette.primary.main,
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  listitem: {
    border: `1px solid ${theme.palette.primary.light}`,
    borderRadius: 3,
    height: 80,
    marginTop: 20,
    marginBottom: 20,
  },
  text: {
    color: theme.palette.common.white,
  },
  icon: {
    color: theme.palette.common.white,
  },
}));

const SideDrawer = (props) => {
  const classes = useStyles();

  const drawer = (
    <>
      <div className={classes.toolbarMargin}></div>
      <div className={classes.listdiv}>
        <List>
          <ListItem
            button
            component={Link}
            to="/appointments"
            className={classes.listitem}
            onClick={() => props.close("close")}
          >
            <ListItemIcon className={classes.icon}>
              <InsertInvitationIcon fontSize="large" />
            </ListItemIcon>
            <ListItemText className={classes.text}>Appointments</ListItemText>
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/services"
            className={classes.listitem}
            onClick={() => props.close("close")}
          >
            <ListItemIcon className={classes.icon}>
              <AssignmentIcon fontSize="large" />
            </ListItemIcon>
            <ListItemText className={classes.text}>Services</ListItemText>
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/accounts"
            className={classes.listitem}
            onClick={() => props.close("close")}
          >
            <ListItemIcon className={classes.icon}>
              <AccountCircle fontSize="large" />
            </ListItemIcon>
            <ListItemText className={classes.text}>Users</ListItemText>
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/updates"
            className={classes.listitem}
            onClick={() => props.close("close")}
          >
            <ListItemIcon className={classes.icon}>
              <SystemUpdateAltIcon fontSize="large" />
            </ListItemIcon>
            <ListItemText className={classes.text}>Updates</ListItemText>
          </ListItem>
        </List>
      </div>
    </>
  );

  return (
    <nav>
      <Hidden smUp implementation="css">
        <Drawer
          variant="temporary"
          open={props.open}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true,
          }}
          onClose={() => props.close(false)}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          className={clsx(classes.drawermini, {
            [classes.drawerOpen]: props.openmini,
            [classes.drawerClose]: !props.openmini,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: props.openmini,
              [classes.drawerClose]: !props.openmini,
            }),
          }}
          variant="permanent"
          open
        >
          {drawer}
        </Drawer>
      </Hidden>
    </nav>
  );
};

export default SideDrawer;
