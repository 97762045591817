import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import withWidth, { isWidthUp } from "@material-ui/core/withWidth";

import { connect } from "react-redux";
import * as actioncreators from "../../../store/actions/actionCreators";

import { ButtonElement } from "../../common/formElements";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 5,
    display: "flex",
    backgroundColor: theme.palette.primary.main,
    justifyContent: "flex-end",
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
  },

  item: {
    margin: 3,
    marginRight: 15,
  },
  buttons: {
    display: "flex",
    justifyContent: "space-around",
  },
  closeicon: {
    color: theme.palette.secondary.light,
  },
}));

const UpdateForm = (props) => {
  const classes = useStyles();
  const upload = (event) => {
    const data = {};
    data.image = event.target.files[0];
    data.caption = "Batman";
    if (data.image) {
      props.UploadImage(data, props.token);
    }
  };
  return (
    <div className={classes.container}>
      <Grid
        container
        justify={isWidthUp("sm", props.width) ? "flex-end" : "center"}
      >
        <Grid className={classes.item}>
          <Button variant="contained" component="label">
            Upload File
            <input
              type="file"
              style={{ display: "none" }}
              name="update"
              onChange={(e) => upload(e)}
            />
          </Button>
        </Grid>

        <Grid className={classes.item}>
          <Button
            variant="contained"
            component="label"
            onClick={() => props.setvisibility()}
          >
            Set Visibility
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

const mapstatetoprops = (state) => {
  return {
    token: state.login.token,
  };
};

const mapdispatchtoprops = (dispatch) => {
  return {
    UploadImage: (data, token) =>
      dispatch(actioncreators.UPUploadImage(data, token)),
  };
};

export default connect(
  mapstatetoprops,
  mapdispatchtoprops
)(withWidth()(UpdateForm));
