import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import UpdateImagesList from "../components/updates/updatesList";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: 10,
    padding: 5,
    backgroundColor: "white",
  },
}));

const UpdateScreen = (props) => {
  const classes = useStyles();
  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <div className={classes.container}>
            <UpdateImagesList />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default UpdateScreen;
