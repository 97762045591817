import * as actiontypes from "../actions/actionTypes";

const initialState = {
  serviceList: [],
  loading: false,
  response: "",
  showId: null,
  data: {
    _id: {
      value: null,
    },
    serviceName: {
      value: "",
      validation: {
        required: true,
      },
      is_valid: true,
      error_msg: "",
      max_length: 50,
    },
  },
};

const ServiceReducer = (state = initialState, action) => {
  let updated;
  let updated_data;

  switch (action.type) {
    case actiontypes.SClearAll:
      updated = initialState;
      return updated;

    case actiontypes.SClearData:
      updated_data = { ...state.data };
      for (const key in updated_data) {
        updated_data[key].value = "";
      }
      updated = {
        ...state,
        data: {
          ...updated_data,
        },
      };
      return updated;

    case actiontypes.SShowID:
      updated = {
        ...state,
        showId: action.value,
      };
      return updated;
    case actiontypes.SServiceLoad:
      updated = {
        ...state,
        serviceList: [...action.value],
      };
      return updated;
    case actiontypes.SLoading:
      updated = {
        ...state,
        loading: action.value,
      };
      return updated;
    case actiontypes.SResponse:
      updated = {
        ...state,
        response: action.value,
      };
      return updated;

    case actiontypes.SDsetstate:
      updated = { ...state.data[action.inputType] };
      for (const key in action.payload) {
        updated[key] = action.payload[key];
      }
      let updated_state = {
        ...state,
        data: {
          ...state.data,
          [action.inputType]: updated,
        },
      };
      return updated_state;

    case actiontypes.SDsetvalue:
      updated = {
        ...state,
        data: {
          ...state.data,
          [action.inputType]: {
            ...state.data[action.inputType],
            value: action.payload.value,
          },
        },
      };
      return updated;

    case actiontypes.SDForEdit:
      let service = state.serviceList.filter(
        (service) => service._id === action.value
      );
      updated = {
        ...state,
        data: {
          ...state.data,
          _id: {
            value: service[0]._id,
          },
          serviceName: {
            ...state.data.serviceName,
            value: service[0].serviceName,
          },
        },
      };
      return updated;

    default:
      break;
  }
  return state;
};

export default ServiceReducer;
