import React from "react";
import Grid from "@material-ui/core/Grid";

import moment from "moment";

import CustomAlert from "../../common/alert";
import ScreenLoad from "../../common/loadingComponent";

import { DatePicker } from "../../common/dateTimeElements";

import {
  TextElement,
  SelectElement,
  ServiceSelect,
} from "../../common/formElements";

const GridOne = (props) => {
  let timeSlot;
  if (props.subLoading) {
    timeSlot = <ScreenLoad />;
  } else if (props.subResponse !== "") {
    timeSlot = <CustomAlert text={props.subResponse} onclick={() => {}} />;
  } else {
    let valueLst = [];
    props.slotsList.map((slot) => {
      if (!slot.is_booked) {
        valueLst = valueLst.concat(slot.slot);
      }
    });
    timeSlot = (
      <SelectElement
        id="slot"
        value={props.slot.value}
        error_msg={props.slot.error_msg}
        valid={!props.slot.is_valid}
        setvalue={props.SetValue}
        values={valueLst}
      />
    );
  }
  return (
    <Grid container style={{ padding: 10 }} xs={12} md={4} justify="center">
      <TextElement
        focus={true}
        width={250}
        label="Name"
        id="name"
        value={props.name.value}
        error_msg={props.name.error_msg}
        valid={!props.name.is_valid}
        setvalue={props.SetValue}
        max_length={props.name.max_length}
        disabled={props.verified ? true : false}
      />
      <TextElement
        width={250}
        label="Phone Number"
        id="phoneNumber"
        value={props.phoneNumber.value}
        error_msg={props.phoneNumber.error_msg}
        valid={!props.phoneNumber.is_valid}
        setvalue={props.SetValue}
        max_length={props.phoneNumber.max_length}
        disabled={props.verified ? true : false}
      />
      <DatePicker
        label="Date"
        id="date"
        value={props.date.value}
        error_msg={props.date.error_msg}
        valid={!props.date.is_valid}
        setvalue={props.SetValue}
        minDate={moment()}
        maxDate={moment().add(4, "d")}
      />
      <ServiceSelect
        id="serviceId"
        value={props.serviceId.value}
        error_msg={props.serviceId.error_msg}
        valid={!props.serviceId.is_valid}
        setvalue={props.SetValue}
        values={props.serviceList}
        disabled={true}
      />
      <div style={{ margin: 10 }}>{timeSlot}</div>
    </Grid>
  );
};

export default GridOne;
