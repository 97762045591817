import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import ServiceList from "../components/services/serviceList";
import TabComponent from "../components/services/tabComponent";

import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({}));

const ServiceScreen = (props) => {
  return (
    <Grid container>
      <Grid item xs={12} md={4}>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <div style={{ padding: 5, margin: 5 }}>
              <ServiceList />
            </div>
          </Grid>
        </Grid>
      </Grid>

      {props.showId ? (
        <Grid item xs={12} md={8}>
          <Grid item xs={12}>
            <div style={{ padding: 5, margin: 5 }}>
              <TabComponent />
            </div>
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  );
};

const mapstatetoprops = (state) => {
  return {
    showId: state.service.showId,
  };
};

export default connect(mapstatetoprops)(ServiceScreen);
