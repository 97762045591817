import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import { connect } from "react-redux";
import * as actioncreators from "../../../store/actions/actionCreators";

import { TextElement, ButtonElement } from "../../common/formElements";
import validator from "../../validation/validator";

const useStyles = makeStyles((theme) => ({
  title: {
    padding: 15,
  },
  buttons: {
    marginTop: 20,
  },
  body: {
    marginTop: 25,
  },
}));

const ServiceForm = (props) => {
  const classes = useStyles();
  const is_edit = props.formdata._id.value ? true : false;

  const submitform = () => {
    const valid = validator(props.formdata, props.SetState);
    if (valid) {
      if (is_edit) {
        props.UpdateForm(
          props.formdata._id.value,
          {
            serviceName: props.formdata.serviceName.value,
          },
          props.token
        );
      } else {
        props.PostForm(
          { serviceName: props.formdata.serviceName.value },
          props.token
        );
      }
    }
  };

  const closeform = () => {
    props.onCancel();
  };
  return (
    <div>
      <Grid className={classes.title}>
        <Typography variant="h6">{`${
          is_edit ? "Edit" : "Add"
        } Service`}</Typography>
      </Grid>
      <Divider variant="middle" />
      <div className={classes.body}>
        <TextElement
          label="Service"
          id="serviceName"
          focus={true}
          value={props.formdata.serviceName.value}
          error_msg={props.formdata.serviceName.error_msg}
          valid={!props.formdata.serviceName.is_valid}
          setvalue={props.SetValue}
          max_length={props.formdata.serviceName.max_length}
        />
      </div>

      <Grid container>
        <Grid item xs={6}>
          <Grid container justify="center">
            <ButtonElement
              buttonText="cancel"
              variant="outlined"
              color="secondary"
              onclick={() => closeform()}
            />
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container justify="center">
            <ButtonElement buttonText="Save" onclick={submitform} />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const mapstatetoprops = (state) => {
  return {
    formdata: state.service.data,
    token: state.login.token,
  };
};
const mapdispatchtoprops = (dispatch) => {
  return {
    SetState: (key, valid, errorMessage) =>
      dispatch(actioncreators.SDSetState(key, valid, errorMessage)),
    SetValue: (entered_text, type) =>
      dispatch(actioncreators.SDSetValue(entered_text, type)),
    PostForm: (data, token) =>
      dispatch(actioncreators.SDSaveService_DB(data, token)),
    UpdateForm: (id, data, token) =>
      dispatch(actioncreators.SDUpdateService_DB(id, data, token)),
  };
};

export default connect(mapstatetoprops, mapdispatchtoprops)(ServiceForm);
